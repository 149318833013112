import { FieldWeb } from 'app/shared/model/item.model';

import { FormItemBaseChangeDetector } from './form-item-change-detector-base';

export class YesNoChangeDetector extends FormItemBaseChangeDetector {
  hasChanged(newItem: FieldWeb, oldItem: FieldWeb): boolean {
    return this.hasYesNoButtonChanged(newItem, oldItem);
  }

  private hasYesNoButtonChanged(newItem: FieldWeb, oldItem: FieldWeb): boolean {
    const checkedChanged = newItem.checked !== oldItem.checked;
    const textInputChanged = newItem.textInputValue !== oldItem.textInputValue;

    return checkedChanged || textInputChanged;
  }
}
