import { FieldWeb } from 'app/shared/model/item.model';

import { FormItemBaseChangeDetector } from './form-item-change-detector-base';

export class SignatureArrayChangeDetector extends FormItemBaseChangeDetector {
  hasChanged(newItem: FieldWeb, oldItem: FieldWeb): boolean {
    return this.hasSignatureArrayChanged(newItem, oldItem);
  }

  private hasSignatureArrayChanged(newItem: FieldWeb, oldItem: FieldWeb): boolean {
    const newItemLength = newItem.signatures?.length ?? 0;
    const oldItemLength = oldItem.signatures?.length ?? 0;

    return newItemLength !== oldItemLength;
  }
}
