import { Component, Input } from '@angular/core';

@Component({
  selector: 'cc-form-static-attachments',
  templateUrl: 'form-static-attachments.component.html',
  styleUrls: ['form-component.scss'],
})
export class FormStaticAttachmentsComponent {
  @Input() attachments;

  constructor() {}
}
