<cc-banner
  type="info"
  badgeText="Tip"
  ccBannerLogic
  [dismissible]="true"
  [showBadge]="true"
  [localStorageKey]="bannerMetadata[bannerKeys.FormAvailableInMobile].localStorageKey"
  [bannerKey]="bannerKeys.FormAvailableInMobile"
  (onDismissBanner)="onClose()"
>
  <span i18n>
    You can also fill out this form out on your phone or tablet using the Dashpivot App, available for
    <a
      [href]="appStoreLink"
      target="_blank"
      data-test="app-store-link"
      class="platform-text"
      rel="noopener noreferrer"
      (click)="trackPlatformLinkClick()"
    >
      iOS</a
    >
    and
    <a
      [href]="googlePlayLink"
      target="_blank"
      data-test="play-store-link"
      class="platform-text"
      rel="noopener noreferrer"
      (click)="trackPlatformLinkClick()"
    >
      Android</a
    >
  </span>
</cc-banner>
