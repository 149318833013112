<!-- Created by -->
<div class="sm-flex sm-flex-col sm-items-end">
  <div class="full-name" data-test="user-full-name">
    <span>
      {{ form.createdBy?.fullName || '[User Full Name]' }}
    </span>
  </div>
  <!-- Creation date -->
  <div class="creation-date">
    <ng-container>
      <span *ngIf="form.createdAt">Created {{ form.createdAt | amDateFormat: 'llll' }} on</span>
      <span *ngIf="!form.createdAt">Timestamp captured on save</span>
    </ng-container>
    <!-- Created source -->
    <ng-container>
      <em
        *ngIf="form.source === formSources.Web || !form.source"
        title="Desktop browser"
        class="cc-icon cc-icon-device-desktop"
      ></em>
      <em
        *ngIf="form.source === formSources.Mobile"
        title="Mobile app"
        class="cc-icon cc-icon-device-mobile"
      ></em>
      <img
        *ngIf="form.source === formSources.Sitemate"
        title="Sitemate"
        src="assets/icons/multi-colored-icons/sitemate-square.svg"
        alt="sitemate-logo"
      />
    </ng-container>
  </div>
</div>
<!-- Avatar -->
<div data-test="user-avatar">
  <cc-user-avatar *ngIf="isEditForm; else defaultAvatar" [user]="form.createdBy"> </cc-user-avatar>
  <ng-template #defaultAvatar>
    <img
      src="/assets/images/placehold-user-avatar.svg"
      alt="User avatar placeholder"
      data-test="default-avatar"
    />
  </ng-template>
</div>
