<div class="title-container">
  <h1 i18n class="sm-text-lg sm-font-medium">{{ title }}</h1>
  <button
    class="cc-icon cc-icon-close close-modal"
    aria-label="Close"
    type="button"
    (click)="closeModal()"
  ></button>
</div>
<p class="sm-text-center sm-text-sm" i18n data-test="subtitle">
  It’s fast and free to sign up to Dashpivot. Create an account to use this template and get access to
  hundreds of other templates.
</p>
<div class="sm-flex sm-flex-col sm-items-center sm-justify-center sm-gap-2">
  <a
    [href]="signupUrl"
    class="button-regular button-green sm-self-center"
    i18n
    type="button"
    target="_blank"
    rel="noopener noreferrer"
    data-test="sign-up-to-download-button"
    >{{ buttonLabel }}</a
  >
  <p class="sm-text-center sm-text-sm sm-text-secondary-text" i18n>
    Already have a Dashpivot account?
    <a
      href="{{ baseUrl }}/login"
      class="sm-font-medium sm-text-secondary-text hover:sm-text-default-text"
      target="_blank"
      rel="noopener noreferrer"
      data-test="login-link"
      >Login</a
    >
  </p>
</div>
