import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ChangeDetectorRef,
  ComponentFactoryResolver,
  AfterViewInit,
  OnChanges,
  OnDestroy,
} from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { FieldKinds, IHierarchy, List } from '@site-mate/dashpivot-shared-library';

import { appControlType } from 'app/apps/app-control-type.model';
import { EditFormField } from 'app/form-fields/common/edit/edit-form-field';
import { EditFormFieldHostDirective } from 'app/form-fields/common/edit/edit-form-field-host.directive';
import { PrefilledTextEditComponent } from 'app/form-fields/prefilled-text/edit/prefilled-text-edit.component';
import { SignonTableEditComponent } from 'app/form-fields/signon-table/edit/signon-table-edit.component';
import { SketchEditComponent } from 'app/form-fields/sketch/edit/sketch-edit.component';
import { YesNoEditComponent } from 'app/form-fields/yes-no/edit/yes-no-edit.component';
import { FormWeb } from 'app/shared/model/form.model';
import { FieldWeb } from 'app/shared/model/item.model';
import { LogicRuleService } from 'app/shared/service/logic-rules.service';

@Component({
  selector: 'cc-edit-form-field',
  templateUrl: './edit-form-field.component.html',
  styleUrls: ['./edit-form-field.component.scss'],
})
export class EditFormFieldComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() field: FieldWeb;
  @Input() instanceName: string;
  @Input() parentId: string;
  @Input() isLandscapeMode: boolean;
  @Input() form: FormWeb;
  @Input() metadataList: Observable<List[]>;
  @Input() invalid: boolean;
  @Input() hierarchy: IHierarchy;

  @Output() onSign = new EventEmitter();
  @Output() onResetSignature = new EventEmitter();
  @Output() handleApprovalSign = new EventEmitter();
  @Output() handleNormalSign = new EventEmitter();
  @Output() handleManualSign = new EventEmitter();
  @Output() resetFields = new EventEmitter();

  @ViewChild(EditFormFieldHostDirective, { static: false })
  ccEditFormFieldHost: EditFormFieldHostDirective;

  controlType = appControlType;
  fieldKinds = FieldKinds;
  formField: EditFormField;
  hasLogicRules = false;
  firstFieldOfLogicRule = false;
  lastFieldOfLogicRule = false;

  private dynamicComponent;
  private unSubscribe = new Subject<void>();

  constructor(
    private readonly ref: ChangeDetectorRef,
    private readonly componentFactoryResolver: ComponentFactoryResolver,
    private readonly logicRuleService: LogicRuleService,
  ) {}

  ngOnInit() {
    this.hasLogicRules = this.logicRuleService.hasLogicRules(this.field);

    switch (this.field.kind) {
      case this.controlType.preFilledText as FieldKinds:
        this.formField = new EditFormField(PrefilledTextEditComponent, {
          model: this.field,
        });
        break;
      case this.controlType.yesNoButton as FieldKinds:
        this.formField = new EditFormField(YesNoEditComponent, {
          model: this.field,
          template: this.hierarchy,
        });
        break;
      case this.controlType.sketch as FieldKinds:
        this.formField = new EditFormField(SketchEditComponent, {
          model: this.field,
        });
        break;
      case this.controlType.signonTable as FieldKinds:
        this.formField = new EditFormField(SignonTableEditComponent, {
          model: this.field,
        });
        break;
      default:
        break;
    }
  }

  ngAfterViewInit(): void {
    if (this.ccEditFormFieldHost && this.formField?.component) {
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(
        this.formField.component,
      );
      const { viewContainerRef } = this.ccEditFormFieldHost;
      const componentRef = viewContainerRef.createComponent(componentFactory);
      this.dynamicComponent = componentRef.instance;
      this.dynamicComponent.fieldData = this.formField.fieldData;
      this.dynamicComponent.isPreview = this.form?.isPreview;

      if (this.hasLogicRules) {
        this.dynamicComponent.resetFields.subscribe((fields: FieldWeb[]) =>
          this.resetLogicRuleFields(fields),
        );
      }

      this.ref.detectChanges();
    }
  }

  resetLogicRuleFields(fields: FieldWeb[]) {
    this.resetFields.emit(fields);
  }

  ngOnChanges(changes): void {
    if (this.dynamicComponent?.onChange) {
      this.dynamicComponent.onChange(changes);
    }
  }

  invalidateItem(item, invalid) {
    item._invalid = invalid;
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
