<button
  type="button"
  class="button-regular sm-flex sm-items-center sm-justify-center sm-gap-1 sm-font-medium {{
    isProcessing
      ? 'button-white sm-ring-2 sm-ring-grey-3 focus:sm-ring-grey-3 sm-ring-inset'
      : 'button-grey-2'
  }}"
  i18n
  [disabled]="isProcessing"
  (click)="openImportModal()"
>
  <img src="assets/icons/multi-colored-icons/sam-square.svg" alt="sam-logo" data-test="sam-logo" />
  @if (isProcessing) {
    Processing... {{ files.processing }}/{{ files.total }}
    <i class="cc-icon cc-icon-spinner-lg"></i>
  } @else {
    Ask Sam to import
  }
</button>
