import { ChangeDetectorRef, Component, OnInit } from '@angular/core';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  FormFieldValidatorFactory,
} from '@site-mate/dashpivot-shared-library';

import { FormBaseComponent } from 'app/form/form-components/form-base.component';
import { FormService } from 'app/form/form.service';
import { SegmentService } from 'app/segment/segment.service';
import { FieldWeb } from 'app/shared/model/item.model';
import { AppUtilService } from 'app/shared/service/app-util.service';

@Component({
  selector: 'cc-form-multi-input',
  templateUrl: 'form-multi-input.component.html',
  styleUrls: ['../../../form/form-components/form-component.scss', 'form-multi-input.component.scss'],
})
export class FormMultiInputComponent extends FormBaseComponent<FieldWeb> implements OnInit {
  editorToolbarOption = [
    [{ header: [1, 2, 3, 4, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ script: 'sub' }, { script: 'super' }],
    [{ color: [] }, { background: [] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
  ];

  formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'script',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
  ];

  constructor(
    protected readonly appUtilService: AppUtilService,
    protected readonly formService: FormService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly segmentService: SegmentService,
  ) {
    super(appUtilService, formService);
  }

  ngOnInit() {
    this.validator = FormFieldValidatorFactory.getValidator(this.model);
  }

  onChange() {
    this.model._dirty = true;
    this.model._invalid = !this.validator.isValid(this.model);
    this.changeDetectorRef.markForCheck();
  }

  onBlur() {
    this.editing = false;
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.FormMultiInputTextTyped),
      this.segmentService,
    );
  }
}
