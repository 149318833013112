/* eslint-disable max-lines */
import { Component, DestroyRef, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { startCase, defaultTo } from 'lodash-es';
import { combineLatest, Subject, takeUntil } from 'rxjs';

import {
  AccountSubscriptionService,
  EventTypes,
  FieldKinds,
  FormFieldValidatorFactory,
  FormSignatureService,
  SignatureFieldActions,
  User,
} from '@site-mate/dashpivot-shared-library';
import { CompanyPlanTypes } from '@site-mate/sitemate-global-shared/lib';

import { FormBaseComponent } from 'app/form/form-components/form-base.component';
import { FormService } from 'app/form/form.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { FieldWeb } from 'app/shared/model/item.model';
import { TeamWeb } from 'app/shared/model/team.model';
import { AccountService } from 'app/shared/service/account.service';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { TeamService } from 'app/shared/service/team.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { AccountUpgradeLimitComponent } from 'app/user/account-upgrade/account-upgrade-limit/account-upgrade-limit.component';
import { UserService } from 'app/user/user.service';

import { LimitUpgradablePlans } from '../template/logic-rule/signature-logic-rule/signature-logic-rule-helpers';

@Component({
  selector: 'cc-form-signature',
  templateUrl: 'form-signature.component.html',
  styleUrls: [
    'form-signature.component.scss',
    '../../../form/form-components/form-component.scss',
    '../../../shared/component/signature-pad/signature-pad.component.scss',
  ],
})
export class FormSignatureComponent extends FormBaseComponent<FieldWeb> implements OnInit, OnDestroy {
  readonly proPlanMaxRules = 3;
  readonly companyPlanTypes = CompanyPlanTypes;
  private readonly destroyRef = inject(DestroyRef);

  @Input() formId: string;
  @Input() formPath: string;
  @Input() instanceName;
  @Input() items;
  @Input() model: FieldWeb;
  @Input() isPreview: boolean;
  @Input() formCreator: User;

  @Output() handleNormalSign = new EventEmitter();
  @Output() handleApprovalSign = new EventEmitter();
  @Output() handleManualSign = new EventEmitter();
  @Output() reset = new EventEmitter();

  canSignApprovalSignature = false;
  canResetSignature = false;
  isApprovalSignatureRestricted = false;
  planType: CompanyPlanTypes;
  canUpgradePlan: boolean;
  isLegacyOrStandardPlan: boolean;
  isLimitUpgradablePlan: boolean;
  signatureLogicRulesNotApplied: boolean;
  isApprovalSignatureUpgradeBannerVisible: boolean;
  team: TeamWeb;
  hasLogic = false;

  public sharedSignatureService = new FormSignatureService();
  restrictedBannerTextApproval =
    'You do not have the correct user type or permission to sign. Please contact your workspace admin if you are required to sign this field.';
  restrictedBannerTextReset =
    'You do not have the correct user type or permission to reset this workflow. Please contact your workspace admin if you are required to reset this workflow.';
  approvalSignatureUpgradeMessage: string;
  resetWorkflowUpgradeMessage: string;
  proPlanUpgradeMessageSecondPart: string;

  private unsubscribe = new Subject<void>();

  constructor(
    protected readonly appUtilService: AppUtilService,
    protected readonly formService: FormService,
    protected readonly toastrService: ToastrService,
    private readonly userService: UserService,
    private readonly teamService: TeamService,
    private readonly ngbModal: NgbModal,
    private readonly eventsService: EventsService,
    private readonly i18nService: TmpI18NService,
    private readonly accountService: AccountService,
    private readonly accountSubscriptionService: AccountSubscriptionService,
  ) {
    super(appUtilService, formService);

    this.formService.onFieldChanged.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(() => {
      this.setUpSignatureField();
    });
  }

  ngOnInit() {
    this.validator = FormFieldValidatorFactory.getValidator(this.model);

    combineLatest([
      this.accountService.currentAccount,
      this.teamService.currentTeam,
      this.userService.currentUser,
    ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.setPlanTypeRestrictions();
        this.setUpSignatureField();
      });
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  private setUpSignatureField() {
    if (this.isApprovalSignature) {
      this.hasLogic = this.model.signatureRules?.length > 0;
      this.setApprovalSignaturePermissions();
      this.setUpgradeBannerMessages();
      this.setResetSignaturePermissions();
    }

    this.isApprovalSignatureRestricted =
      !this.model.signatureUrl && !this.canSignApprovalSignature && !this.notFirstUnSigned();

    this.isApprovalSignatureUpgradeBannerVisible =
      this.signatureLogicRulesNotApplied &&
      !this.notFirstUnSigned() &&
      this.hasLogic &&
      !this.model.signatureUrl;
  }

  private setUpgradeBannerMessages() {
    if (this.isLimitUpgradablePlan) {
      this.approvalSignatureUpgradeMessage = this.i18nService.getMessage('signatureLogicRuleExceeded');
      this.resetWorkflowUpgradeMessage = this.i18nService.getMessage('signatureLogicRuleExceeded');
      this.proPlanUpgradeMessageSecondPart = this.i18nService.getMessage(
        'signatureLogicRuleExceededSecondPart',
      );
    } else {
      this.approvalSignatureUpgradeMessage = `Unable to apply Approval Signature logic to this signature, as it is not supported on the ${startCase(
        this.planType,
      )} plan.`;
      this.resetWorkflowUpgradeMessage = `Unable to apply Approval Signature logic to this Workflow Reset, as it is not supported on the ${startCase(
        this.planType,
      )} plan.`;
    }
  }

  private setPlanTypeRestrictions() {
    this.team = this.teamService.getCurrentTeam();
    this.planType = this.accountService.getAccountPlanType();
    this.isLimitUpgradablePlan = LimitUpgradablePlans.includes(this.planType);
    const isApprovalSignatureLogicRestricted =
      !this.accountSubscriptionService.canAddApprovalSignatureLogic();

    if (isApprovalSignatureLogicRestricted) {
      this.signatureLogicRulesNotApplied = isApprovalSignatureLogicRestricted;
    } else if (this.isLimitUpgradablePlan && this.model.signatureRules?.length) {
      this.setLimitUpgradablePlanRestrictions();
    }
  }

  private setApprovalSignaturePermissions() {
    if (this.signatureLogicRulesNotApplied) {
      this.canSignApprovalSignature = true;
    } else {
      const user = this.userService.getCurrentUser();
      const teamPath = this.team.path ?? this.team.companyMetadata?.path;

      this.canSignApprovalSignature = this.sharedSignatureService.canPerformFieldAction({
        user,
        teamPath,
        fieldAction: SignatureFieldActions.ApprovalSignature,
        rules: this.model.signatureRules || [],
        formCreatorId: this.formCreator?._id,
        fields: this.items,
      });
    }
  }

  private setResetSignaturePermissions() {
    if (this.signatureLogicRulesNotApplied) {
      this.canSignApprovalSignature = true;
    } else {
      this.canResetSignature = this.sharedSignatureService.canPerformFieldAction({
        user: this.userService.getCurrentUser(),
        teamPath: this.team.path ?? this.team.companyMetadata?.path,
        fieldAction: SignatureFieldActions.ResetWorkflow,
        rules: this.model.signatureRules || [],
        formCreatorId: this.formCreator?._id,
        fields: this.items,
      });
    }
  }

  private setLimitUpgradablePlanRestrictions() {
    if (this.planType === CompanyPlanTypes.Pro || this.planType === CompanyPlanTypes.Premium) {
      this.signatureLogicRulesNotApplied = !this.sharedSignatureService.shouldApplyLogicForUpgradablePlan(
        this.model.signatureRules,
        this.planType,
      );
    }
  }

  notFirstUnSigned() {
    const allUnSigned = this.items.filter((i) => {
      return i.kind === FieldKinds.Signature && !i.signatureUrl;
    });
    if (allUnSigned && allUnSigned.length > 0) {
      return this.model.id !== allUnSigned[0].id;
    }
    return false;
  }

  lastSigned() {
    const allSigned = this.items.filter((i) => {
      return i.kind === FieldKinds.Signature && i.signatureUrl;
    });
    if (allSigned && allSigned.length > 0) {
      return this.model.id === allSigned[allSigned.length - 1].id;
    }

    return false;
  }

  onPreviewSign() {
    this.toastrService.warnByKey('formPreviewWarningSignature');
  }

  approvalSign() {
    this.onFieldChange();
    this.handleApprovalSign.emit({ id: defaultTo(this.model.id, this.model._id) });
  }

  normalSign() {
    this.onFieldChange();
    this.handleNormalSign.emit(this.model);
  }

  get isApprovalSignature() {
    return this.model.kind === FieldKinds.Signature;
  }

  get isManualSignature() {
    return this.model.isManualSignature;
  }

  get isNormalSignature() {
    return this.model.kind === FieldKinds.SignatureArray && !this.isManualSignature;
  }

  addManualSignature() {
    this.onFieldChange();
    this.handleManualSign.emit(this.model);
  }

  resetSignature() {
    this.reset.emit();
  }

  openUpgradeWorkspaceModal() {
    this.sendUnlockApprovalSignatureEvent();

    const upgradeWorkspaceModal = this.ngbModal.open(AccountUpgradeLimitComponent, {
      windowClass: 'modal-500',
      modalDialogClass: 'modal-dialog-top',
    });
    const modalTitleMessage = this.isLimitUpgradablePlan
      ? 'unlockConditionalApprovalSignatureLogic'
      : 'unlockApprovalSignatureLogic';
    const modalSubtitleMessage = this.isLimitUpgradablePlan
      ? 'unlockApprovalSignatureLogicSubtitleProPlan'
      : 'unlockApprovalSignatureLogicSubtitle';

    upgradeWorkspaceModal.componentInstance.title = this.i18nService.getMessage(modalTitleMessage);
    upgradeWorkspaceModal.componentInstance.subtitle = this.i18nService.getMessage(modalSubtitleMessage);
    upgradeWorkspaceModal.componentInstance.imageSrc = '/assets/images/usage-limits/visitor-users.svg';
    upgradeWorkspaceModal.componentInstance.imageAlt = 'three industrial workers';
  }

  private sendUnlockApprovalSignatureEvent() {
    this.eventsService.trackEvent(EventTypes.UpgradeClicked, {
      Context: 'Unlock Approval Signature Logic Banner',
    });
  }
}
