import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { BehaviorSubject, Observable, Subject, combineLatest, of } from 'rxjs';
import { catchError, map, switchMap, takeUntil } from 'rxjs/operators';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  FieldKinds,
  FormFieldValidatorFactory,
  IField,
  IPersonField,
  Template,
  User,
  UserTypes,
  IUserWithSummaryOrWithoutData,
  PersonFieldUtils,
  PathService,
} from '@site-mate/dashpivot-shared-library';

import { FormBaseComponent } from 'app/form/form-components/form-base.component';
import { FormService } from 'app/form/form.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { SegmentService } from 'app/segment/segment.service';
import { IFormPersonOption } from 'app/shared/model/form-person.option.model';
import { FormWeb } from 'app/shared/model/form.model';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { UserService } from 'app/user/user.service';

import { FormPersonService } from './form-person.service';

@Component({
  selector: 'cc-form-person',
  templateUrl: 'form-person.component.html',
  styleUrls: ['form-person.component.scss', '../../../form/form-components/form-component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormPersonComponent extends FormBaseComponent<IField> implements OnInit, OnDestroy {
  private readonly unSubscribe = new Subject<void>();

  @Input() form: FormWeb;

  selectedOption: IFormPersonOption | (IUserWithSummaryOrWithoutData & { fullName: string }) | null = null;
  currentUser: Observable<User>;
  unknownUser = false;
  loading = true;
  error = false;
  folderId?: string = null;
  archivedUserNotice: string;

  selectedUserIds$ = new BehaviorSubject<string[]>([]);
  personOptions$ = new BehaviorSubject<IFormPersonOption[] | null>(null);

  constructor(
    protected readonly appUtilService: AppUtilService,
    protected readonly formService: FormService,
    private readonly segmentService: SegmentService,
    private readonly formPersonService: FormPersonService,
    private readonly userService: UserService,
    private readonly cdr: ChangeDetectorRef,
    private readonly errorHandler: ErrorHandler,
    private readonly i18nService: TmpI18NService,
    private readonly pathService: PathService,
  ) {
    super(appUtilService, formService);
    this.archivedUserNotice = this.i18nService.getMessage('archivedUserNotice');
  }

  private get context() {
    return 'Form';
  }

  public fieldKinds = FieldKinds;

  ngOnInit(): void {
    this.validator = FormFieldValidatorFactory.getValidator(this.model);

    if (this.model.kind !== FieldKinds.Person) {
      return;
    }

    if (!Array.isArray(this.model.data.userIds)) {
      this.model.data.userIds = [];
    }

    this.currentUser = this.userService.currentUser;

    this.model._dirty = !!this.model._dirty;

    this.initializeState();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.model && changes.model.currentValue) {
      const userIds = (this.model as IPersonField).data?.userIds || [];
      this.selectedUserIds$.next(userIds as string[]);
    }

    if (changes.form) {
      const template = this.form.template as unknown as Template;
      this.folderId = template.parents?.[0];
      this.loadFormPersonOptions(this.folderId);
    }
  }

  private initializeState(): void {
    combineLatest([this.personOptions$, this.selectedUserIds$])
      .pipe(
        switchMap(([options, userIds]) => {
          const selectedUserId = userIds[0];
          if (!selectedUserId || !options) return of(null);

          const foundOption = options.find((option) => option._id === selectedUserId);
          if (foundOption) {
            return of(foundOption);
          }

          return this.formPersonService.searchUser(selectedUserId).pipe(
            catchError((error) => {
              this.errorHandler.handle(error);
              this.setErrorState(true);
              return of(null);
            }),
          );
        }),
        takeUntil(this.unSubscribe),
      )
      .subscribe((option: IFormPersonOption | IUserWithSummaryOrWithoutData | null) => {
        this.unknownUser = false;

        if (!option) {
          this.setLoadingState(this.selectedUserIds$.value.length > 0);
          this.cdr.markForCheck();
          return;
        }

        if ('fullName' in option) {
          // This is an IFormPersonOption, no additional processing needed
          this.selectedOption = option;
        } else if (PersonFieldUtils.isUserSummary(option)) {
          this.selectedOption = {
            ...option,
            fullName: PersonFieldUtils.personFieldLabel(option),
          };
        } else {
          this.selectedOption = null;
          this.unknownUser = true;
        }

        this.setLoadingState(false);
        this.cdr.markForCheck();
      });
  }

  private userSort(userA: IFormPersonOption, userB: IFormPersonOption, currentUser: User): number {
    if (userA._id === currentUser.sitemateUserId) return -1;
    if (userB._id === currentUser.sitemateUserId) return 1;

    return userA.label.localeCompare(userB.label) ?? 0;
  }

  private mergeAndSortPeople(
    contributors: IFormPersonOption[],
    visitors: IFormPersonOption[],
    currentUser: User,
  ) {
    return [...contributors, ...visitors].sort((userA, userB) => this.userSort(userA, userB, currentUser));
  }

  private setLoadingState(loading: boolean) {
    this.loading = loading;
    this.cdr.markForCheck();
  }

  private setErrorState(error: boolean) {
    this.error = error;
    this.cdr.markForCheck();
  }

  private createMockPersonOptions() {
    const firstName = 'Example';
    const lastName = 'User';
    const baseUser = {
      firstName,
      lastName,
      label: `${firstName} ${lastName}`,
      fullName: `${firstName} ${lastName}`,
      email: 'test@sitemate.com',
    };

    return Array.from({ length: 4 }, (_, index) => ({
      ...baseUser,
      _id: `mocked_${index + 1}`,
      role: index < 2 ? UserTypes.Contributor : UserTypes.Visitor,
      avatar: `/assets/images/form-users/placeholder-${index + 1}.png`,
    }));
  }

  private loadFormPersonOptions(folderId: string | undefined) {
    this.setErrorState(false);

    if (folderId === undefined) {
      this.personOptions$.next(this.createMockPersonOptions());

      this.setLoadingState(false);
      return;
    }

    this.setLoadingState(true);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [companyId, projectId, teamId] = this.pathService.extractFolderIds(this.form.template.path);
    const isTemplateAtTeamLevel = !!teamId;

    combineLatest([
      this.userService.currentUser,
      this.formPersonService.getPersonItems(folderId, { isTeam: isTemplateAtTeamLevel }),
    ])
      .pipe(
        takeUntil(this.unSubscribe),
        map(([currentUser, { contributors, visitors }]) =>
          this.mergeAndSortPeople(contributors, visitors, currentUser),
        ),
        catchError((error) => {
          this.errorHandler.handle(error);
          this.setErrorState(true);
          return of([] as IFormPersonOption[]);
        }),
      )
      .subscribe((result) => {
        this.personOptions$.next(result);
      });
  }

  private roleToGroupMap = {
    [UserTypes.Contributor]: 'Contributors',
    [UserTypes.Visitor]: 'Visitors',
  };
  groupByFn = (item: IFormPersonOption) => {
    return this.roleToGroupMap[item.role] || 'Other';
  };

  onDropdownSelect(selectedOption: IFormPersonOption) {
    this.selectItems(selectedOption);
    this.updatePropertiesOnChange();
  }

  private selectItems(selectedOption: IFormPersonOption) {
    if (selectedOption) {
      void EventNotifierService.notify(
        new DashpivotEvent(EventTypes.FormPersonAssigned, { Context: this.context }),
        this.segmentService,
      );
    }

    (this.model as IPersonField).data.userIds = selectedOption ? [selectedOption._id] : [];
    this.cdr.markForCheck();

    this.selectedUserIds$.next(selectedOption ? [selectedOption._id] : []);
  }

  private updatePropertiesOnChange() {
    this.model._dirty = true;
    this.model._invalid = !this.validator.isValid(this.model);
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
