import { FieldKinds } from '@site-mate/dashpivot-shared-library';

import { AlwaysFalseChangeDetector } from './always-false-change-detector';
import { CategoryChangeDetector } from './category-change-detector';
import { DateChangeDetector } from './date-change-detector';
import { DatePlainChangeDetector } from './date-plain-change-detector';
import { FormItemBaseChangeDetector } from './form-item-change-detector-base';
import { PersonChangeDetector } from './person-change-detector';
import { PhotoChangeDetector } from './photo-change-detector';
import { SignatureArrayChangeDetector } from './signature-array-change-detector';
import { SignatureChangeDetector } from './signature-change-detector';
import { TextInputChangeDetector } from './single-line-input-change-detector';
import { SketchChangeDetector } from './sketch-change-detector';
import { TableChangeDetector } from './table-change-detector';
import { YesNoChangeDetector } from './yes-no-change-detector';

export class FormItemChangeDetectorFactory {
  static fieldChangeDetectorMapping = {
    [FieldKinds.SingleLineInput]: TextInputChangeDetector,
    [FieldKinds.MultiLineInput]: TextInputChangeDetector,
    [FieldKinds.Date]: DateChangeDetector,
    [FieldKinds.DatePlain]: DatePlainChangeDetector,
    [FieldKinds.DateRangePlain]: DatePlainChangeDetector,
    [FieldKinds.YesNoCheckbox]: YesNoChangeDetector,
    [FieldKinds.Table]: TableChangeDetector,
    [FieldKinds.PrefilledTable]: TableChangeDetector,
    [FieldKinds.Photo]: PhotoChangeDetector,
    [FieldKinds.Sketch]: SketchChangeDetector,
    [FieldKinds.Category]: CategoryChangeDetector,
    [FieldKinds.Person]: PersonChangeDetector,
    [FieldKinds.Signature]: SignatureChangeDetector,
    [FieldKinds.SignatureArray]: SignatureArrayChangeDetector,
    // Field kinds set to "Always False" can't be changed in a form - they are static
    [FieldKinds.PreFilledText]: AlwaysFalseChangeDetector,
    [FieldKinds.Attachment]: AlwaysFalseChangeDetector,
    [FieldKinds.SignonTable]: AlwaysFalseChangeDetector,
    [FieldKinds.PageBreak]: AlwaysFalseChangeDetector,
  };

  static createChangeDetector(kind: FieldKinds): FormItemBaseChangeDetector {
    const ChangeDetectorClass = this.fieldChangeDetectorMapping[kind];
    if (!ChangeDetectorClass) {
      throw new Error(`Unsupported field kind: ${kind}`);
    }
    return new ChangeDetectorClass();
  }
}
