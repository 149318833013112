import { Injectable } from '@angular/core';

import { EventNotifierService, EventTypes, Event, SamEvent } from '@site-mate/dashpivot-shared-library';

import { EventsService } from 'app/shared/service/events/events.service';

@Injectable({ providedIn: 'root' })
export class SamEventsService extends EventsService {
  trackEvent(event: EventTypes, metadata: Record<string, unknown> = {}): void {
    Object.assign(metadata, this.defaultMetadata);

    const newEvent = new SamEvent(event, metadata as Event['metadata']);
    void EventNotifierService.notify(newEvent, this.segmentService);
  }
}
