import { FieldWeb } from 'app/shared/model/item.model';

import { FormItemBaseChangeDetector } from './form-item-change-detector-base';
import { FormDateUtil } from '../form-date-util.service';

export class DateChangeDetector extends FormItemBaseChangeDetector {
  hasChanged(newItem: FieldWeb, oldItem: FieldWeb): boolean {
    return !this.isDateTimeEqual(newItem, oldItem);
  }

  private isDateTimeEqual(newField: FieldWeb, oldField: FieldWeb): boolean {
    if (newField.isSingleDate) {
      if (!FormDateUtil.isDateEqual(newField.startDate, oldField.startDate)) {
        return false;
      }
      if (newField.isDateAndTime) {
        if (!FormDateUtil.isTimeEqual(newField.startDate, oldField.startDate)) {
          return false;
        }
      }
    } else {
      if (
        !FormDateUtil.isDateEqual(newField.startDate, oldField.startDate) ||
        !FormDateUtil.isDateEqual(newField.endDate, oldField.endDate)
      ) {
        return false;
      }
      if (newField.isDateAndTime) {
        if (
          !FormDateUtil.isTimeEqual(newField.startDate, oldField.startDate) ||
          !FormDateUtil.isTimeEqual(newField.endDate, oldField.endDate)
        ) {
          return false;
        }
      }
    }
    return true;
  }
}
