import { Component, EventEmitter, Input, Output, ViewChild, ElementRef, HostListener } from '@angular/core';

import { ToastrService } from 'app/shared/service/toastr.service';

@Component({
  selector: 'cc-form-attachment',
  templateUrl: 'form-attachment.component.html',
  styleUrls: ['form-attachment.component.scss', '../../../form/form-components/form-component.scss'],
})
export class FormAttachmentComponent {
  readonly pdfMimeType = 'application/pdf';

  @Input() attachmentUploading = [];
  @Input() isPreview: boolean;
  @Input() attachments = [];
  @Input() preUploadGuard: () => Promise<boolean>;

  @Output() deleteAttachment = new EventEmitter();
  @Output() handleUploadAttachment = new EventEmitter();

  @ViewChild('fileInput') fileInput: ElementRef<HTMLInputElement>;

  constructor(private readonly toastrService: ToastrService) {}

  @HostListener('keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      void this.uploadGuard(event);
    }
  }

  async uploadGuard(event) {
    if (event.target === this.fileInput.nativeElement) {
      return;
    }

    if (this.isPreview) {
      this.toastrService.warnByKey('formPreviewWarningUploadAttachment');
      return;
    }

    if (this.preUploadGuard) {
      event.stopPropagation();
      event.preventDefault();
      const allow = await this.preUploadGuard();
      if (!allow) {
        return;
      }
    }

    this.fileInput.nativeElement.click();
  }

  upload(event) {
    if (this.isPreview) {
      this.toastrService.warnByKey('formPreviewWarningUploadAttachment');
      event.stopPropagation();
      event.preventDefault();
      return;
    }

    this.handleUploadAttachment.emit(event);
  }

  onDeleteAttachment(attachment) {
    this.deleteAttachment.emit({ attachment, attachments: this.attachments });
  }
}
