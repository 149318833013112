import { ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError, finalize, from, mergeMap, noop, Observable, of, Subject, takeUntil, tap } from 'rxjs';

import { IHierarchy, Photo, TableCellKinds, FormAttachment } from '@site-mate/dashpivot-shared-library';

import { FieldWeb } from 'app/shared/model/item.model';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { ToastrService } from 'app/shared/service/toastr.service';

import { SamImportFileType } from '../model/sam-import-file-type.enum';
import { ITaskExecution } from '../model/task-execution.model';
import {
  ISamImportModalResult,
  SamImportModalComponent,
} from '../sam-import-modal/sam-import-modal.component';
import { SamService } from '../sam.service';

@Component({
  selector: 'cc-ask-sam-to-import',
  templateUrl: './ask-sam-to-import.component.html',
  styleUrls: ['./ask-sam-to-import.component.scss'],
})
export class AskSamToImportComponent {
  private readonly destroy$ = new Subject<void>();
  @Input() hierarchy: IHierarchy;
  @Input() formItem: FieldWeb;
  @Input() taskId: string;
  @Output() onSamResponseReceived = new EventEmitter<ITaskExecution>();

  isProcessing = false;
  files = {
    processing: 0,
    total: 0,
  };

  constructor(
    private readonly samService: SamService,
    protected readonly modal: NgbModal,
    private changeDetection: ChangeDetectorRef,
    private readonly errorHandler: ErrorHandler,
    private readonly toastrService: ToastrService,
  ) {}

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  openImportModal() {
    // TODO: change to GlobalWebModalService
    const modalRef = this.modal.open(SamImportModalComponent, {
      centered: true,
      container: '.form-modal-container',
    });
    modalRef.componentInstance.hierarchy = this.hierarchy;
    modalRef.result.then(this.onSelected.bind(this)).catch(noop);
  }

  private onSelected(selection: ISamImportModalResult) {
    if (!selection?.files?.length) {
      return;
    }

    this.isProcessing = true;
    this.files.processing = 1;
    this.files.total = selection.files.length;
    this.changeDetection.markForCheck();

    from(selection.files)
      .pipe(
        takeUntil(this.destroy$),
        mergeMap((file) => this.executeTask(file, selection.type)),
        tap((response) => {
          this.handleSamResponse(response);
        }),
        finalize(() => {
          this.isProcessing = false;
          this.changeDetection.markForCheck();
          this.toastrService.successByKey('samHasFinishedProcessing');
        }),
      )
      .subscribe();
  }

  private handleSamResponse(response: ITaskExecution) {
    if (response) {
      this.onSamResponseReceived.emit(response);
    }

    if (this.files.processing === this.files.total) {
      return;
    }

    this.files.processing += 1;
    this.changeDetection.markForCheck();
  }

  private addPhotoToRow(response: ITaskExecution, photo: Photo): ITaskExecution {
    const row = response.result?.rows[0];
    const photoColumn = row?.columns.find((column) => column.kind === TableCellKinds.Photo);
    if (photoColumn) {
      photoColumn.photos = [photo];
    }

    return response;
  }

  private addPDFToRow(response: ITaskExecution, attachment: FormAttachment): ITaskExecution {
    const row = response.result?.rows[0];
    const attachmentColumn = row?.columns.find((column) => column.kind === TableCellKinds.Attachment);
    if (attachmentColumn) {
      attachmentColumn.attachments = [attachment];
    }

    return response;
  }

  private executeTask(file: Photo | FormAttachment, type: SamImportFileType): Observable<ITaskExecution> {
    let url: string;

    if (type === SamImportFileType.photo) {
      const photo = file as Photo;
      url = photo.url.originalFile;
    } else {
      const attachment = file as FormAttachment;
      url = attachment.urlDownload;
    }

    return this.samService.executeTask(this.taskId, this.formItem, [{ url, type }]).pipe(
      takeUntil(this.destroy$),
      tap((response) => {
        if (type === SamImportFileType.photo) {
          this.addPhotoToRow(response, file as Photo);
        } else if (type === SamImportFileType.pdf) {
          this.addPDFToRow(response, file as FormAttachment);
        }
      }),
      catchError((error) => {
        this.errorHandler.handle(error);
        return of(null);
      }),
    );
  }
}
