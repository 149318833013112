<div class="form-group row" [class.invalid-field]="model._invalid">
  <div class="col-12 row">
    <cc-form-field-label class="col-3 form-label" [model]="model" [label]="model.description" />
    <div class="col-9">
      <div class="form-control-static">
        <div *ngIf="!model.readOnly">
          <div
            *ngIf="!isMobile"
            class="text-preview"
            (focus)="editing = true"
            (click)="editing = true"
            [hidden]="editing"
            tabindex="0"
          >
            <div *ngIf="model.content">
              {{ model.content }}
            </div>
            <div *ngIf="!model.content" class="text-muted" i18n>Type text...</div>
          </div>
          <input
            type="text"
            [(ngModel)]="model.content"
            (keyup)="onChange($event); onFieldChange()"
            class="form-control text-input"
            data-test="form-single-input"
            placeholder="Type text..."
            [id]="model.id"
            ccScrollInputFocus=".form-modal"
            (blur)="onBlur()"
            *ngIf="editing || isMobile"
            i18n-placeholder="Type text..."
            [ccFocus]="!isMobile"
          />
        </div>
        <div *ngIf="model.readOnly" class="text-preview">
          {{ model.content }}
        </div>
      </div>
    </div>
  </div>
</div>
