<div
  class="mx-auto main-container"
  [attr.ngbAutofocus]="isLibraryView"
  [class.library-view]="isLibraryView"
  [class.is-landscape]="template?.orientation === templateOrientations.Landscape"
>
  <cc-form-modal-tool-bar
    *ngIf="!isTemplateEditorPreview; else formLoading"
    [formName]="form?.name"
    [showActions]="showActions"
    [canToggleActivity]="editForm"
    [activitiesState]="showActivities"
    (remove)="remove()"
    (download)="download()"
    (save)="onSave()"
    (customise)="customizeTemplate()"
    [canDelete]="canDelete"
    [isWorking]="saving"
    [canSave]="canSave"
    (close)="onClose()"
    [form]="form"
    [templateName]="templateName"
    (send)="onSend()"
    (useThisTemplate)="onUseThisTemplate()"
    [isCloning]="isCloning"
    [isPreview]="isPreview"
    (clone)="clone()"
    (toggleActivities)="onToggleActivities()"
    [navigateOnSignUpToFormModal]="navigateOnSignUpToFormModal"
    [templateId]="templateId"
    class="full-modal"
  >
  </cc-form-modal-tool-bar>

  <div class="form-container">
    <div class="mx-3 mb-3 cc-alert-primary align-self-center" role="alert" *ngIf="isPreview">
      <i class="mr-2 cc-icon cc-icon-information"></i>
      <span i18n>
        This is a <strong>preview only</strong>. Click the 'Use this template' button in the top right to
        start creating forms in your account
      </span>
    </div>
    <cc-form-available-on-mobile-alert
      class="sm-mb-4 sm-self-center"
      *ngIf="form?._id && editForm && !navigateOnSignUpToFormModal"
      showBadge="true"
    ></cc-form-available-on-mobile-alert>
    <div
      class="sm-mb-4 sm-flex sm-w-max sm-rounded-lg sm-bg-default-light-blue sm-p-2"
      *ngIf="form && editForm && navigateOnSignUpToFormModal"
    >
      <span class="sm-text-xl sm-font-semibold sm-text-default-dark-blue" i18n>
        Try filling out your {{ form.name }}, then press Save in the top right corner to continue
      </span>
    </div>
    <div class="sm-w-full" [class.form-content]="editForm" [class.show-activities]="showActivities">
      <div
        *ngIf="!isFormReady"
        class="loading-spinner edit-form-empty-state sm-mb-4"
        data-test="loading-spinner-form-not-ready"
      >
        <i class="cc-icon cc-icon-spinner-3x" aria-hidden="true"></i>
      </div>
      <virtual-scroller
        *ngIf="isFormReady"
        #scroll
        [items]="form.items"
        [parentScroll]="scroll.window"
        [bufferAmount]="3"
      >
        <div
          class="modal-body app-modal-body form sm-mb-4"
          [class.template-editor-preview]="isTemplateEditorPreview"
          data-test="form-body"
        >
          <div class="animate-fade-in">
            <cc-form-header
              [headerFields]="headerFields"
              [company]="company"
              [filePath]="filePath"
              [form]="form"
              [template]="template"
              [isEditForm]="editForm"
            ></cc-form-header>
            <div class="form-group row" *ngIf="template?.counterIsActivated">
              <div class="col-sm-2 col-form-label form-label">Automated Form Number</div>
              <div class="col form-label sm-flex sm-items-center">
                <div data-test="counter-preview">
                  {{ form.automatedFormNumber }}
                </div>
              </div>
            </div>
            <div class="sm-px-0">
              <div #container>
                <ng-container *ngFor="let field of form.items">
                  <cc-edit-form-field
                    *ngIf="!field.dependsOn?.fieldId"
                    [field]="field"
                    [invalid]="!!field._invalid"
                    [isLandscapeMode]="template?.orientation === templateOrientations.Landscape"
                    [instanceName]="template?.instanceName"
                    [hierarchy]="template"
                    [form]="form"
                    [metadataList]="metadataList$"
                    [parentId]="parentId"
                    (onSign)="onSign($event)"
                    (onResetSignature)="onResetSignature($event)"
                    (handleApprovalSign)="handleApprovalSign($event)"
                    (handleNormalSign)="handleNormalSign($event)"
                    (handleManualSign)="handleManualSign($event)"
                    (resetFields)="resetFormFields($event)"
                  ></cc-edit-form-field>
                </ng-container>
              </div>

              <div class="row form-group">
                <div class="row col-12">
                  <label class="col-3 form-label" i18n>Form Attachments</label>
                  <div class="col-9 form-attachment-container">
                    <cc-form-attachment
                      [preUploadGuard]="uploadAttachmentGuard"
                      [isPreview]="form.isPreview"
                      [attachments]="form.dynamicAttachments"
                      (handleUploadAttachment)="handleUploadAttachment($event)"
                      [attachmentUploading]="attachmentUploading"
                      (deleteAttachment)="onDeleteAttachment($event)"
                    >
                    </cc-form-attachment>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col">
                  <cc-form-static-attachments
                    [attachments]="form.staticAttachments"
                  ></cc-form-static-attachments>
                </div>
              </div>
            </div>
          </div>
        </div>
      </virtual-scroller>
      <cc-activity-bar
        [comments]="activities"
        [show]="showActivities"
        *ngIf="editForm"
        learnMoreLink="https://intercom.help/dashpivot/en/articles/2947996-form-versioning"
        (versionClick)="showVersionDiff($event)"
      >
      </cc-activity-bar>
    </div>
  </div>
</div>

<ng-template #formLoading>
  <div *ngIf="navigateOnSignUpToFormModal" class="loading-spinner">
    <i class="cc-icon cc-icon-spinner-3x" aria-hidden="true"></i>
  </div>
</ng-template>
