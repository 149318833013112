import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { noop, Subject, takeUntil } from 'rxjs';

import { IHierarchy, Photo, FormAttachment } from '@site-mate/dashpivot-shared-library';

import { FormPhotoModalComponent } from 'app/form/form-components/form-photo-modal.component';
import { FileUploaderModalService } from 'app/shared/service/file-uploader-modal.service';
import { UuidService } from 'app/shared/service/uuid/uuid.service';
import { environment } from 'environments/environment';

import { SamImportFileType } from '../model/sam-import-file-type.enum';

export interface ISamImportModalResult {
  type: SamImportFileType;
  files: Photo[] | FormAttachment[];
}

@Component({
  selector: 'cc-sam-import-modal',
  templateUrl: 'sam-import-modal.component.html',
  styleUrls: ['sam-import-modal.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SamImportModalComponent implements OnDestroy {
  @Input() hierarchy: IHierarchy;

  private readonly destroy$ = new Subject<void>();

  private readonly uploaderLabels = {
    title: 'Import',
    subtitle: 'Sam will automatically convert your records into rows in the table.',
    subtitleImage: 'assets/icons/multi-colored-icons/sam-square.svg',
    finishButton: 'Start processing',
  };

  constructor(
    private readonly modal: NgbActiveModal,
    protected readonly photoModal: NgbModal,
    private readonly fileUploaderModalService: FileUploaderModalService,
    private readonly uuidService: UuidService,
  ) {}

  onPhotoSelected() {
    // TODO: change to GlobalWebModalService
    const modalRef = this.photoModal.open(FormPhotoModalComponent, {
      windowClass: 'form-photo-modal',
      keyboard: false,
      container: '.form-modal-container',
    });

    const componentInstance = modalRef.componentInstance as FormPhotoModalComponent;
    componentInstance.hierarchy = this.hierarchy;
    componentInstance.formRef = true;
    componentInstance.uploaderLabels = this.uploaderLabels;
    componentInstance.acceptedFiles = 'image/jpg, image/bmp, image/jpeg, image/png, image/tiff';

    modalRef.result
      .then((photos) => {
        this.modal.close({
          type: SamImportFileType.photo,
          files: photos || [],
        });
      })
      .catch(noop);
  }

  onPDFSelected() {
    this.fileUploaderModalService.add({
      _id: this.uuidService.getUuid(),
      url: `${environment.apiUrl}forms/attachments`,
      acceptedFiles: 'application/pdf',
      labels: this.uploaderLabels,
      maxFileSize: 50,
    });

    this.fileUploaderModalService.closeUploader$.pipe(takeUntil(this.destroy$)).subscribe((result) => {
      this.modal.close({
        type: SamImportFileType.pdf,
        files: result.uploadedFiles || [],
      });
    });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  close(): void {
    this.modal.dismiss();
  }
}
