import { FieldWeb } from 'app/shared/model/item.model';

import { FormItemBaseChangeDetector } from './form-item-change-detector-base';

export class CategoryChangeDetector extends FormItemBaseChangeDetector {
  hasChanged(newItem: FieldWeb, oldItem: FieldWeb): boolean {
    const newItemSelectedItems = JSON.stringify(newItem.selectedItems ?? []);
    const oldItemSelectedItems = JSON.stringify(oldItem.selectedItems ?? []);

    return newItemSelectedItems !== oldItemSelectedItems;
  }
}
