import { IPersonField } from '@site-mate/dashpivot-shared-library';

import { FormItemBaseChangeDetector } from './form-item-change-detector-base';

export class PersonChangeDetector extends FormItemBaseChangeDetector {
  hasChanged(newItem: IPersonField, oldItem: IPersonField): boolean {
    const previousValue = oldItem.data?.userIds ?? [];
    const nextValue = newItem.data?.userIds ?? [];

    return previousValue.join() !== nextValue.join();
  }
}
