import { Injectable } from '@angular/core';
import { unionBy } from 'lodash-es';
import { map } from 'rxjs/operators';

import { CategorySourceTypes, List } from '@site-mate/dashpivot-shared-library';

import { FormCategoryOption } from 'app/shared/model/form-category.option.model';
import { FieldWeb } from 'app/shared/model/item.model';
import { ListsHandler } from 'app/shared/model/lists/lists-handler.model';

@Injectable({ providedIn: 'root' })
export class FormCategoryService {
  getCategoryItems(field: FieldWeb, metadataList, parentId: string) {
    return metadataList.pipe(
      map((lists: List[]) => {
        switch (field.categorySource) {
          case CategorySourceTypes.Manual:
            return this.getItemsForManualList(field);
          case CategorySourceTypes.List:
            return this.getItemsForList(field, lists, parentId);
          default:
            return [] as FormCategoryOption[];
        }
      }),
    );
  }

  private getItemsForManualList(field: FieldWeb) {
    return field.items.map(
      (item) =>
        ({
          id: item.id,
          value: item.value,
          label: item.value,
          style: item.style,
        }) as FormCategoryOption,
    );
  }

  private getItemsForList(field: FieldWeb, lists: List[], parentId: string) {
    const selectedList = lists?.find((entry) => entry.id === field.reference);
    const filteredList = ListsHandler.service.filterDeployedListItems(selectedList, parentId);

    const listOptions = filteredList.items.map(
      (item) =>
        ({
          id: item.id,
          value: item.value,
          label: item.value,
          isDeployed: item.deployedParents?.includes(parentId),
          style: item.style,
        }) as FormCategoryOption,
    );

    return unionBy(field.selectedItems as FormCategoryOption[], listOptions, (item) => item.id);
  }
}
