import { Component, OnInit } from '@angular/core';
import { DifferencePipe } from 'ngx-moment';

import { FieldKinds, FormFieldValidatorFactory } from '@site-mate/dashpivot-shared-library';

import { FormBaseComponent } from 'app/form/form-components/form-base.component';
import { FormDateUtil } from 'app/form/form-date-util.service';
import { FormService } from 'app/form/form.service';
import { FieldWeb } from 'app/shared/model/item.model';
import { AppUtilService } from 'app/shared/service/app-util.service';

@Component({
  selector: 'cc-form-date',
  templateUrl: 'form-date.component.html',
  styleUrls: ['../../../form/form-components/form-component.scss', './form-date.component.scss'],
})
export class FormDateComponent extends FormBaseComponent<FieldWeb> implements OnInit {
  expiry;
  differencePipe;

  constructor(
    protected readonly appUtilService: AppUtilService,
    protected readonly formService: FormService,
  ) {
    super(appUtilService, formService);
  }

  ngOnInit(): void {
    this.differencePipe = new DifferencePipe();
    this.validator = FormFieldValidatorFactory.getValidator(this.model);

    // convert the dates to the structures the date/time components need
    this.model._startTime = FormDateUtil.convertToTimeStruct(this.model.startDate);
    this.model._startDate = FormDateUtil.convertToDateStruct(this.model.startDate);
    this.model._endTime = FormDateUtil.convertToTimeStruct(this.model.endDate);
    this.model._endDate = FormDateUtil.convertToDateStruct(this.model.endDate);

    if (this.model.kind === (this.controlType.date as FieldKinds)) {
      this.calculateExpiry(this.model._startDate);
    }
  }

  calculateExpiry(date) {
    if (this.model.isExpiryDate && date) {
      const startDate = new Date(date.year, date.month - 1, date.day);
      const d = new Date();
      const today = new Date(d.getFullYear(), d.getMonth(), d.getDate());
      this.expiry = this.differencePipe.transform(startDate, today, 'days');
    }
  }

  validate() {
    // convert back from component structure to real dates
    this.model.startDate = FormDateUtil.convertDateToUTCString(this.model._startDate, this.model._startTime);
    this.model.endDate = FormDateUtil.convertDateToUTCString(this.model._endDate, this.model._endTime);
    this.model._invalid = !this.validator.isValid(this.model);
    this.model._dirty = true;
  }

  validateStartTime(startTime) {
    this.model._startTime = startTime;
    this.validate();
  }

  validateEndTime(endTime) {
    this.model._endTime = endTime;
    this.validate();
  }
}
