import { PlatformLocation } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Form, FormSignatureService, User } from '@site-mate/dashpivot-shared-library';
import { CompanyPlanTypes } from '@site-mate/sitemate-global-shared/lib';

import { ModalBaseEscComponent } from 'app/shared/component/modal-base-esc.component';
import { FormWeb } from 'app/shared/model/form.model';
import { UserService } from 'app/user/user.service';

interface IColumnSelector {
  _id: string;
  label: string;
  disabled?: boolean;
}

@Component({
  selector: 'cc-reset-workflow',
  templateUrl: './reset-workflow.component.html',
  styleUrls: ['./reset-workflow.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ResetWorkflowComponent extends ModalBaseEscComponent implements OnInit {
  form: FormWeb;
  planType: CompanyPlanTypes;
  columnSelectorValues: IColumnSelector[] = [];
  selectedWorkflowColumn: string = null;
  submitButtonLabel = 'Reset';
  user: User;

  constructor(
    protected readonly platformLocation: PlatformLocation,
    protected readonly activeModal: NgbActiveModal,
    private readonly userService: UserService,
    private readonly sharedSignatureService: FormSignatureService,
  ) {
    super(platformLocation, activeModal);
  }

  ngOnInit(): void {
    this.user = this.userService.getCurrentUser();

    const currentColumn = this.form?.isInColumn;
    const columns = this.form?.columns || [];
    const previousColumns = columns.slice(0, currentColumn - 1);
    const isLegacyOrStandard = [CompanyPlanTypes.Legacy, CompanyPlanTypes.Standard].includes(this.planType);

    previousColumns.forEach(({ _id, title }, index) => {
      const canResetToColumn = this.sharedSignatureService.validateResetWorkflowAction(
        this.form as unknown as Form,
        _id,
        this.user,
      );

      this.columnSelectorValues.push({
        _id,
        label: `Column ${index + 1} : ${title}`,
        disabled: !canResetToColumn && !isLegacyOrStandard,
      });
    });

    const isOneOptionOnly = previousColumns.length === 1;
    if (isOneOptionOnly && !this.columnSelectorValues[0].disabled) {
      this.selectedWorkflowColumn = this.columnSelectorValues[0]._id;
    }
  }

  resetWorkflow(): void {
    this.closeModal(this.selectedWorkflowColumn);
  }
}
