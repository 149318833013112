<div class="form-group row">
  <label class="default-label col-sm-2 col-form-label form-label"> </label>

  <div class="col">
    <div class="form-control-static">
      <div *ngFor="let attachment of attachments">
        <a [href]="attachment.urlDownload" target="_blank" rel="noopener" class="text-main">
          <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
          {{ attachment.filename }}</a
        >
      </div>
    </div>
  </div>
</div>
