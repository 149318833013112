import { Event } from '@site-mate/dashpivot-shared-library';

export class TimingMarker {
  constructor(
    public readonly event: Event,
    private readonly startTime = new Date(),
  ) {}

  public getStartTime() {
    return this.startTime.toString();
  }

  public getStartUtcMilliseconds() {
    return this.getUtcMilliseconds(this.startTime);
  }

  public getElapsedTime() {
    return this.getUtcMilliseconds(new Date()) - this.getUtcMilliseconds(this.startTime);
  }

  private getUtcMilliseconds(date: Date) {
    const offset = date.getTimezoneOffset() * 60000;
    return date.getTime() - offset;
  }
}
