/* eslint-disable no-undef */
import { AfterViewInit, Component, Input } from '@angular/core';

import { ToastrService } from 'app/shared/service/toastr.service';

import { SketchUtils } from '../sketch-utils';

@Component({
  selector: 'cc-sketch-pad',
  templateUrl: 'sketch-pad.component.html',
  styleUrls: ['sketch-pad.component.scss'],
})
export class SketchPadComponent implements AfterViewInit {
  id = Date.now();
  canvas;

  @Input()
  model;

  isFreeDraw;
  shapeConfig = {
    top: 30,
    left: 30,
    fill: 'transparent',
    stroke: '#000',
  };

  constructor(private readonly toastService: ToastrService) {}

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.canvas = new fabric.Canvas(`${this.id}`);
      this.enableFreeDraw();
      this.model.canvasObj = this.canvas;
      if (this.model.canvas) {
        this.canvas.loadFromJSON(this.model.canvas, () => {
          this.canvas
            .getObjects()
            .filter((o) => o.type === 'text')
            .forEach((o) => {
              o.hasControls = false;
            });
          this.canvas.renderAll();
        });
      }
    }, 100);
  }

  private addShape(shape) {
    this.disableFreeDraw();
    this.canvas.add(shape);
    this.canvas.setActiveObject(shape);
  }

  addSquare() {
    const s = new fabric.Rect({
      ...this.shapeConfig,
      width: 80,
      height: 80,
    });
    this.addShape(s);
  }

  addTriangle() {
    const t = new fabric.Triangle({
      ...this.shapeConfig,
      width: 80,
      height: 80,
    });
    this.addShape(t);
  }

  addRect() {
    const rect = new fabric.Rect({
      ...this.shapeConfig,
      width: 130,
      height: 80,
    });
    this.addShape(rect);
  }

  addCircle() {
    const circle = new fabric.Circle({
      ...this.shapeConfig,
      radius: 40,
    });
    this.addShape(circle);
  }

  addLine() {
    const line = new fabric.Line([50, 100, 200, 150], {
      ...this.shapeConfig,
    });
    this.addShape(line);
  }

  addText(textInput) {
    if (textInput.value) {
      const text = new fabric.Text(textInput.value, {
        ...this.shapeConfig,
        fontFamily: 'helvetica',
        fontSize: 30,
        fontWeight: 100,
        fill: '#000',
        hasRotatingPoint: true,
        centerTransform: true,
      });
      text.hasControls = false;
      this.addShape(text);
      textInput.value = '';
    } else {
      this.toastService.warnByKey('inputTextBeforeAdd');
    }
  }

  toggleFreeDraw() {
    this.isFreeDraw = !this.isFreeDraw;
    this.canvas.isDrawingMode = this.isFreeDraw;
  }

  disableFreeDraw() {
    this.isFreeDraw = false;
    this.canvas.isDrawingMode = false;
  }

  enableFreeDraw() {
    this.isFreeDraw = true;
    this.canvas.isDrawingMode = true;
  }

  deleteSelected() {
    const activeObjects = this.canvas.getActiveObjects();

    if (activeObjects.length) {
      this.canvas.remove(...activeObjects);
    } else {
      this.toastService.warnByKey('selectShape');
    }
  }

  getBackgroundUrl() {
    return SketchUtils.getSketchpadBackground(this.model);
  }
}
