import { Component } from '@angular/core';

import { FormFieldValidator } from '@site-mate/dashpivot-shared-library';

import { EditFormFieldComponent } from 'app/form-fields/common/edit/edit-form-field.component';
import { FieldWeb } from 'app/shared/model/item.model';

@Component({
  selector: 'cc-form-page-break',
  templateUrl: './form-page-break.component.html',
  styleUrls: ['./form-page-break.component.scss'],
})
export class FormPageBreakComponent implements EditFormFieldComponent {
  fieldData: any;
  model: FieldWeb;
  validator: FormFieldValidator;
  isPreview?: boolean;
}
