<cc-banner size="sm">
  <div class="sm-flex sm-cursor-default sm-items-center sm-justify-center sm-gap-1">
    <img src="assets/icons/multi-colored-icons/sam-square.svg" alt="sam-logo" data-test="sam-logo" />
    Sam helped populate this table
    <i
      [gwTooltip]="samTooltipContent"
      gwTooltipIndicatorPosition="top-right"
      gwTooltipWindowClass="sm-text-default-text"
      class="cc-icon cc-icon-question"
    ></i>
  </div>
</cc-banner>
<ng-template #samTooltipContent>
  <div class="sm-space-x-1" i18n data-test="sam-tooltip">
    <img
      class="sm-inline-block"
      src="assets/icons/multi-colored-icons/sam-square.svg"
      alt="sam-logo"
      data-test="sam-logo"
    />
    Sam is an AI agent that can help perform tasks within Sitemate’s products.
  </div>
</ng-template>
