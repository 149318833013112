<div class="form-group row" [class.invalid-field]="model._invalid">
  <div class="row col-12">
    <cc-form-field-label class="col-3 form-label" [model]="model" [label]="model.description" />
    <div class="col-9">
      <div class="form-control-static">
        <div *ngIf="!model.readOnly">
          <div
            *ngIf="!isMobile"
            class="text-preview multi-line-text-preview"
            (click)="editing = true"
            (focus)="editing = true"
            [hidden]="editing"
            tabindex="0"
          >
            <div *ngIf="model.content" [innerHTML]="model.content | safeHtml" class="editor-content"></div>
            <div *ngIf="!model.content" class="text-muted" i18n>Type text...</div>
          </div>
          <cc-rich-editor
            *ngIf="editing || isMobile"
            [model]="model"
            (blur)="onBlur()"
            (change)="onChange(); onFieldChange()"
            [editorToolbarOption]="editorToolbarOption"
            [formats]="formats"
            [autoFocus]="true"
          >
          </cc-rich-editor>
        </div>
        <div
          *ngIf="model.readOnly"
          [innerHTML]="model.content | safeHtml"
          class="editor-content text-preview multi-line-text-preview"
        ></div>
      </div>
    </div>
  </div>
</div>
