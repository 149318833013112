/* eslint-disable @typescript-eslint/no-unused-vars */
import { FieldWeb } from 'app/shared/model/item.model';

import { FormItemBaseChangeDetector } from './form-item-change-detector-base';

export class AlwaysFalseChangeDetector extends FormItemBaseChangeDetector {
  hasChanged(_newItem: FieldWeb, _oldItem: FieldWeb): boolean {
    return false;
  }
}
