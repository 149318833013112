import isEqual from 'lodash-es/isEqual';

import { FieldWeb } from 'app/shared/model/item.model';
import { IdNormalizer } from 'app/shared/model/utils/id-normalizer.model';

export class PhotoComparisonUtils {
  static hasPhotoChanged(newItem: FieldWeb, oldItem: FieldWeb): boolean {
    if (oldItem.photos?.length !== newItem.photos?.length) {
      return true;
    }

    const hasChanged = oldItem.photos?.find((photo, index) => {
      const newPhoto = newItem.photos[index];
      return (
        new IdNormalizer(photo).value() !== new IdNormalizer(newPhoto).value() ||
        photo.description !== newPhoto?.description ||
        !isEqual(photo.tagGroups, newPhoto.tagGroups)
      );
    });

    return Boolean(hasChanged);
  }
}
