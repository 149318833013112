<div class="form-group row">
  <div class="row col-12" [class.invalid-field]="model._invalid" [class.has-logic-rules]="hasLogicRules">
    <cc-form-field-label [model]="model" [label]="model.content" class="col-3 form-label" />
    <div class="flex-grow-landscape yes-no-buttons-container sm-w-1/3 sm-pl-1">
      <div class="form-control-static">
        {{ model.description }}
      </div>
    </div>
    <div class="form-control-static yes-no-buttons-container sm:sm-col-span-2">
      <div class="row" *ngIf="!model.readOnly">
        <div
          class="mb-2 mb-md-0"
          [class.col-md-4]="!dependsOnAnotherField"
          [class.col-md-5]="dependsOnAnotherField"
        >
          <div data-toggle="buttons" class="btn-group btn-group-toggle btn-group-sm">
            <label
              class="default-label btn mb-0 yes btn-secondary"
              [class.active]="model.checked === values.Yes"
              [for]="model.id + '-yes'"
            >
              <input
                type="radio"
                [value]="values.Yes"
                [name]="model.id"
                [id]="model.id + '-yes'"
                [(ngModel)]="model.checked"
                (ngModelChange)="onChangeModel($event)"
              />
              <span i18n>Yes</span>
            </label>
            <label
              class="default-label btn mb-0 no btn-secondary"
              [class.active]="model.checked === values.No"
              [for]="model.id + '-no'"
            >
              <input
                type="radio"
                [value]="values.No"
                [name]="model.id"
                [id]="model.id + '-no'"
                [(ngModel)]="model.checked"
                (ngModelChange)="onChangeModel($event)"
              />
              <span i18n>No</span>
            </label>
            <label
              class="default-label btn mb-0 na btn-secondary"
              [class.active]="model.checked === values.NA"
              [for]="model.id + '-not-available'"
              *ngIf="model.hasNaButton"
            >
              <input
                type="radio"
                [value]="values.NA"
                [name]="model.id"
                [id]="model.id + '-not-available'"
                [(ngModel)]="model.checked"
                (ngModelChange)="onChangeModel($event)"
              />
              <span i18n>NA</span>
            </label>
          </div>
        </div>
        <div
          *ngIf="model.hasTextInput"
          [class.col-md-8]="!dependsOnAnotherField"
          [class.col-md-7]="dependsOnAnotherField"
        >
          <div class="row form-group">
            <label class="default-label col-sm-4" [for]="model.id + '-text'">
              <small>
                <strong class="breakable-text">{{ model.textInputLabel }}</strong>
              </small>
            </label>
            <div class="col-sm-8">
              <input
                [id]="model.id + '-text'"
                class="form-control form-control-sm"
                type="text"
                [name]="model.id"
                placeholder="Type text"
                i18n-placeholder="Type text"
                [(ngModel)]="model.textInputValue"
                (keyup)="onFieldChange()"
              />
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="model.readOnly">
        <div class="row">
          <div class="col-sm-6">
            <span *ngIf="model.checked === values.Yes" i18n>Yes</span>
            <span *ngIf="model.checked === values.No" i18n>No</span>
            <span *ngIf="model.checked === values.NA" i18n>N/A</span>
          </div>
          <div *ngIf="model.hasTextInput" class="col-sm-6">
            <small>
              <strong>{{ model.textInputLabel }}</strong>
            </small>
            <small>{{ model.textInputValue }}</small>
          </div>
        </div>
      </div>
      <span class="clearfix"></span>
    </div>
  </div>
</div>
