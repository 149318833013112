<div class="modal-tool">
  <ul class="nav">
    <li class="nav-item" *ngIf="isPreview">
      <span
        class="p-2 ml-2 btn cc-btn-subtle-gray d-flex align-items-center line-height-normal"
        data-test="go-back-button"
        (click)="close.emit()"
        (keydown)="close.emit()"
      >
        <i class="mr-1 cc-icon cc-icon-arrow-long-left"></i>
        <span i18n class="font-weight-medium">Go back</span>
      </span>
    </li>
    <li class="nav-item" *ngIf="showActions && !navigateOnSignUpToFormModal">
      <a
        id="delete-form-action"
        class="nav-link delete-link action-button icon"
        (click)="form?._id && onRemove()"
        *ngIf="canDelete"
        ngbPopover="Delete"
        [class.disabled]="!form?._id"
        placement="bottom"
        triggers="mouseenter:mouseleave"
        [class.sm-opacity-50]="!form?._id"
      >
        <i class="align-middle cc-icon cc-icon-delete sm-text-default-red"> </i>
      </a>
      <a
        id="download-form-action"
        class="nav-link other-link download-link action-button icon"
        [class.first-action-link]="!canDelete"
        (click)="isEmbedLibraryApp ? openSignUpToDownloadModal() : download.emit()"
        *ngIf="!appUtilService.isMobile"
        ngbPopover="Download PDF"
        placement="bottom"
        triggers="mouseenter:mouseleave"
      >
        <i class="align-middle cc-icon cc-icon-download-pdf"> </i>
      </a>
      <a
        *ngIf="!isEmbedLibraryApp"
        id="send-form-action"
        class="nav-link other-link send-pdf-link action-button icon"
        [class.first-action-link]="!canDelete && appUtilService.isMobile"
        (click)="send.emit()"
        ngbPopover="Send PDF"
        placement="bottom"
        triggers="mouseenter:mouseleave"
      >
        <i class="align-middle cc-icon cc-icon-send"></i>
      </a>
      <a
        *ngIf="!isEmbedLibraryApp"
        id="clone-form-action"
        class="nav-link clone-link action-button icon"
        (click)="clone.emit()"
        ngbPopover="Clone"
        placement="bottom"
        triggers="mouseenter:mouseleave"
      >
        <i class="cc-icon cc-icon-spinner-fw" *ngIf="isCloning"></i>
        <i class="align-middle cc-icon cc-icon-clone"></i>
      </a>
    </li>
    <li class="mt-auto mb-auto ml-auto nav-item hidden-xs-down">
      <button
        type="button"
        *ngIf="isPreview"
        class="btn cc-btn-primary-constructive sm-mr-1 sm-font-medium"
        data-test="use-this-template-button"
        i18n
        (click)="useThisTemplate.emit()"
      >
        Use this template
      </button>
      <a
        [href]="signupUrl"
        *ngIf="isEmbedLibraryApp"
        class="btn cc-btn-primary-constructive sm-mr-1 sm-font-medium visited:sm-text-white"
        data-test="use-this-template-iframe-button"
        i18n
        target="_blank"
        rel="noopener noreferrer"
      >
        Use this template now
      </a>
      <button
        type="button"
        *ngIf="!isPreview && navigateOnSignUpToFormModal"
        class="btn customise-button"
        data-test="customise-template-button"
        i18n
        (click)="customise.emit()"
      >
        <span class="customise-button-text"> Customise this {{ formName }} </span>
      </button>
      <ng-container *ngIf="!isPreview && !isEmbedLibraryApp">
        <button
          type="button"
          *ngIf="canToggleActivity && !navigateOnSignUpToFormModal"
          data-test="show-activity-button"
          class="btn btn-light action-button toggle-activity"
          (click)="onToggleActivity()"
        >
          <i class="mr-1 align-middle cc-icon cc-icon-activity icon"></i>
          <span i18n class="hidden-md-down">
            <span *ngIf="!activitiesState">Show</span>
            <span *ngIf="activitiesState">Hide</span>
            Activity
          </span>
        </button>
        <button
          type="button"
          [disabled]="isWorking"
          *ngIf="canSave"
          (click)="save.emit()"
          class="btn btn-success action-button"
          data-test="save-button"
          i18n
        >
          Save
        </button>
        <span *ngIf="!canSave" (mouseleave)="saveTemplateTooltip.close()">
          <ng-template #tipContentSaveTemplate
            ><p class="mb-0 text-left white-space-initial">
              Save is only available once you have made changes to the form. Click 'x' to close this form.
            </p></ng-template
          >
          <button
            type="button"
            placement="auto"
            tooltipClass="tip-container tooltip-form-toolbar"
            i18n
            class="btn btn-success action-button disabled"
            data-test="disabled-save-button"
            triggers="manual"
            #saveTemplateTooltip="ngbTooltip"
            [autoClose]="false"
            (mouseenter)="saveTemplateTooltip.open()"
            [ngbTooltip]="tipContentSaveTemplate"
          >
            Save
          </button>
        </span>
        <button
          type="button"
          *ngIf="!navigateOnSignUpToFormModal && !isEmbedLibraryApp"
          class="btn btn-light action-button icon"
          data-cy="form-modal-close-action"
          (click)="close.emit()"
        >
          <i class="cc-icon cc-icon-close"></i>
        </button>
      </ng-container>
    </li>
    <span class="clearfix"></span>
  </ul>
</div>
