<div class="row align-items-center">
  <div class="col" [ngSwitch]="field.kind">
    <cc-form-single-input *ngSwitchCase="controlType.singleLineInput" [model]="field"></cc-form-single-input>

    <cc-form-multi-input *ngSwitchCase="controlType.multiLineInput" [model]="field"></cc-form-multi-input>

    <!-- seems to perform slowly -->
    <cc-form-date *ngSwitchCase="controlType.date" [model]="field"></cc-form-date>

    <ng-container *ngSwitchCase="fieldKinds.Signature">
      <ng-container *ngTemplateOutlet="signatureFieldTemplate; context: { item: field }"></ng-container>
    </ng-container>

    <!-- seems to perform slowly -->
    <ng-container *ngSwitchCase="fieldKinds.SignatureArray">
      <ng-container *ngTemplateOutlet="signatureFieldTemplate; context: { item: field }"></ng-container>
    </ng-container>

    <!-- seems to perform slowly -->
    <ng-container *ngSwitchCase="controlType.table">
      <ng-container
        *ngTemplateOutlet="tableFieldTemplate; context: { item: field, template: template }"
      ></ng-container>
    </ng-container>

    <!-- seems to perform slowly -->
    <ng-container *ngSwitchCase="controlType.prefilledTable">
      <ng-container
        *ngTemplateOutlet="tableFieldTemplate; context: { item: field, template: template }"
      ></ng-container>
    </ng-container>

    <cc-form-photo
      *ngSwitchCase="controlType.photo"
      [model]="field"
      [hierarchy]="hierarchy"
      [isPreview]="form?.isPreview"
      [isLandscapeMode]="isLandscapeMode"
    ></cc-form-photo>

    <cc-form-person
      *ngSwitchCase="controlType.person"
      [model]="field"
      [form]="form"
      [isPreview]="form?.isPreview"
    ></cc-form-person>

    <cc-form-category
      *ngSwitchCase="controlType.category"
      [model]="field"
      [metadataList]="metadataList"
      [parentId]="parentId"
      [invalid]="!!field._invalid"
      (resetFields)="resetLogicRuleFields($event)"
    ></cc-form-category>

    <cc-form-page-break *ngSwitchCase="controlType.pageBreak"></cc-form-page-break>

    <ng-template *ngSwitchDefault ccEditFormFieldHost></ng-template>
  </div>
</div>

<div *ngIf="field.logicRules?.length" class="logic-rule-fields">
  <ng-container *ngFor="let rule of field.logicRules">
    <ng-container *ngIf="rule.isTrue">
      <ng-container *ngFor="let dependentField of form.items">
        <div *ngIf="dependentField.dependsOn?.logicRuleId === rule.id" class="dependent-field">
          <div class="p-2 mr-3 col-auto logic-rule-icon">
            <i class="cc-icon cc-icon-logic secondary"></i>
          </div>
          <cc-edit-form-field
            [field]="dependentField"
            [invalid]="dependentField._invalid"
            [isLandscapeMode]="isLandscapeMode"
            [instanceName]="instanceName"
            [form]="form"
            [metadataList]="metadataList"
            [parentId]="parentId"
            [hierarchy]="hierarchy"
            (onSign)="onSign.emit($event)"
            (onResetSignature)="onResetSignature.emit($event)"
            (handleApprovalSign)="handleApprovalSign.emit($event)"
            (handleNormalSign)="handleNormalSign.emit($event)"
            (handleManualSign)="handleManualSign.emit($event)"
            (resetFields)="resetFields.emit($event)"
            class="dependent-field-{{ dependentField.kind }}"
          ></cc-edit-form-field>
        </div>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template #tableFieldTemplate let-item="field">
  <cc-form-table
    [model]="field"
    [template]="hierarchy"
    [formPath]="form.path"
    [formId]="form._id"
    [isPreview]="form?.isPreview"
    (invalid)="invalidateItem(field, $event)"
    [invalid]="!!field._invalid"
    [isLandscapeMode]="isLandscapeMode"
    [hierarchy]="hierarchy"
  ></cc-form-table>
</ng-template>

<ng-template #signatureFieldTemplate let-item="field">
  <cc-form-signature
    [model]="field"
    [isPreview]="form?.isPreview"
    [formPath]="form.path"
    [formId]="form._id"
    [instanceName]="instanceName"
    [items]="form.items"
    [formCreator]="form.createdBy"
    (onSign)="onSign.emit($event)"
    (reset)="onResetSignature.emit($event)"
    (handleApprovalSign)="handleApprovalSign.emit($event)"
    (handleNormalSign)="handleNormalSign.emit($event)"
    (handleManualSign)="handleManualSign.emit($event)"
  >
  </cc-form-signature>
</ng-template>
