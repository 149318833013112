import { NgTemplateOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'cc-sam-banner',
  templateUrl: './sam-banner.component.html',
  styleUrls: ['./sam-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SamBannerComponent {
  @ViewChild('samTooltipContent', { static: true }) samTooltipContent!: TemplateRef<NgTemplateOutlet>;
}
