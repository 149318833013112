<div>
  <div class="non-printable form-group row" [class.invalid-field]="model._invalid">
    <div class="row col-12">
      <cc-form-field-label [model]="model" class="col-3 form-label" [label]="model.description" />
      <div class="col-9 form-inline">
        <div class="form-control-static">
          <div *ngIf="isApprovalSignature" class="sm-flex sm-flex-col sm-items-start sm-gap-2">
            <span>
              <cc-form-signature-list
                *ngIf="model.signatureUrl"
                [formPath]="formPath"
                [isReadOnly]="model.readOnly"
                [kind]="model.kind"
                [signatures]="[model]"
              ></cc-form-signature-list>
              <button
                *ngIf="!model.signatureUrl && !model.readOnly"
                type="button"
                class="btn btn-success btn-sign-approval"
                i18n
                (click)="isPreview ? onPreviewSign() : approvalSign()"
                [disabled]="notFirstUnSigned() || !canSignApprovalSignature"
                id="form-sign-approval"
              >
                Sign to approve<i class="cc-icon cc-icon-signature sm-pl-1"></i>
              </button>
              <small
                *ngIf="
                  canSignApprovalSignature ? !model.signatureUrl : notFirstUnSigned() && !model.signatureUrl
                "
                class="text-muted"
                data-test="approval-signature-text"
                i18n
                >to move this {{ instanceName }} to the next column
              </small>
            </span>
            <cc-banner
              *ngIf="isApprovalSignatureRestricted && !signatureLogicRulesNotApplied"
              data-test="approval-signature-restricted-banner"
              type="warning"
              icon="warning"
              alignment="center"
              width="fit-content"
              [bannerText]="restrictedBannerTextApproval"
            >
              <a
                href="https://intercom.help/dashpivot/en/articles/8329198-approval-signature-logic"
                class="!sm-underline"
                target="_blank"
                rel="noopener"
                i18n
                >Learn More.</a
              >
            </cc-banner>

            <cc-banner
              *ngIf="isApprovalSignatureUpgradeBannerVisible"
              class="sm-text-left"
              data-test="approval-signature-upgrade-banner"
              type="warning"
              icon="warning"
              alignment="center"
              width="fit-content"
              [bannerText]="approvalSignatureUpgradeMessage"
            >
              <span i18n>
                <button
                  class="sm-text-default-blue sm-underline"
                  data-test="open-upgrade-modal-signature"
                  type="button"
                  (click)="openUpgradeWorkspaceModal()"
                >
                  Upgrade
                </button>
                <span *ngIf="!isLimitUpgradablePlan">
                  from the
                  {{ planType | titlecase }} Plan to unlock Approval Signature Logic.
                </span>
                <span *ngIf="isLimitUpgradablePlan">
                  {{ proPlanUpgradeMessageSecondPart }}
                </span>
              </span>
            </cc-banner>
          </div>

          <div *ngIf="isNormalSignature">
            <cc-form-signature-list
              *ngIf="model.signatures"
              [formPath]="formPath"
              [isReadOnly]="model.readOnly"
              [kind]="model.kind"
              [signatures]="model.signatures"
            ></cc-form-signature-list>
            <button
              type="button"
              class="btn btn-success"
              data-test="btn-normal-signature"
              i18n
              (click)="isPreview ? onPreviewSign() : normalSign()"
              *ngIf="!model.readOnly"
              id="form-sign"
            >
              Sign<i class="cc-icon cc-icon-signature sm-pl-1"></i>
            </button>
          </div>

          <div *ngIf="isManualSignature">
            <cc-form-signature-list
              *ngIf="model.signatures"
              [formPath]="formPath"
              [isManualSignature]="model.isManualSignature"
              [isReadOnly]="model.readOnly"
              [kind]="model.kind"
              [signatures]="model.signatures"
            ></cc-form-signature-list>
            <button
              *ngIf="!model.readOnly"
              type="button"
              class="btn btn-success"
              data-test="btn-manual-signature"
              i18n
              (click)="isPreview ? onPreviewSign() : addManualSignature()"
              id="form-sign-manual"
            >
              Sign manually<i class="cc-icon cc-icon-signature sm-pl-1"></i>
            </button>
          </div>
        </div>
      </div>
    </div>

    <div class="row col-12" *ngIf="lastSigned()">
      <label class="default-label col-2 form-label"> </label>
      <div class="col-10 sm-flex sm-flex-col sm-gap-4">
        <div class="text-muted">
          <i class="fa fa-fw fa-lock"></i>
          <span i18n>
            The fields above are locked since the form is signed. You can click the button below to clear the
            signatures and unlock the fields.
          </span>
        </div>
        <div class="sm-relative sm-text-center">
          <button
            type="button"
            class="btn btn-primary sm-relative sm-z-10"
            i18n
            (click)="resetSignature()"
            [disabled]="!canResetSignature && !signatureLogicRulesNotApplied"
            [class.workflow-reset-disabled]="!canResetSignature && !signatureLogicRulesNotApplied"
            id="form-sign-change"
            data-test="reset-workflow"
          >
            Reset Workflow
          </button>

          <div class="lock-line sm-absolute"></div>
        </div>
        <cc-banner
          *ngIf="!canResetSignature && !signatureLogicRulesNotApplied"
          data-test="reset-workflow-restricted-banner"
          type="warning"
          icon="warning"
          alignment="center"
          width="fit-content"
          [bannerText]="restrictedBannerTextReset"
          class="sm-self-center"
        >
          <a
            href="https://intercom.help/dashpivot/en/articles/8329198-approval-signature-logic"
            class="!sm-underline"
            target="_blank"
            rel="noopener"
            i18n
            >Learn More.</a
          >
        </cc-banner>

        <cc-banner
          *ngIf="signatureLogicRulesNotApplied && hasLogic"
          class="sm-text-left"
          data-test="reset-workflow-upgrade-banner"
          type="warning"
          icon="warning"
          alignment="center"
          width="fit-content"
          [bannerText]="resetWorkflowUpgradeMessage"
        >
          <span i18n>
            <button
              class="sm-text-default-blue sm-underline"
              data-test="open-upgrade-modal-workflow"
              (click)="openUpgradeWorkspaceModal()"
              type="button"
            >
              Upgrade
            </button>

            <span *ngIf="!isLimitUpgradablePlan">
              from the
              {{ planType | titlecase }} Plan to unlock Approval Signature Logic.
            </span>
            <span *ngIf="isLimitUpgradablePlan">
              {{ proPlanUpgradeMessageSecondPart }}
            </span>
          </span>
        </cc-banner>
      </div>
    </div>
  </div>
  <div class="printable form-group row col-12">
    <label class="default-label col-sm-2 col-form-label">
      {{ model.description }}
    </label>
    <div class="col">
      <div class="form-control-static">
        <div *ngIf="model.signatureUrl" class="d-flex align-items-center">
          <img [src]="model.signatureUrl" style="max-height: 5.7rem; max-width: 14rem" />
          <div class="ml-3">{{ model.fullName }} {{ model.signedOn | amDateFormat: 'lll' }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
