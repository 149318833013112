import { Location, PlatformLocation } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  Field,
  FormAttachment,
  FormulasVersions,
  HeaderFooterDefaultFields,
  HeaderFooterField,
  ITemplatePreviewConfig,
  TemplateOrientations,
} from '@site-mate/dashpivot-shared-library';

import { TemplatesService } from 'app/apps/templates.service';
import { FormEventsService } from 'app/form/form-events.service';
import { IPreviewCompanyFormParams, IPreviewFormParams } from 'app/form/form-preview.model';
import { FormService } from 'app/form/form.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { ListsService } from 'app/lists/lists.service';
import { SamService } from 'app/sam/sam.service';
import { SegmentService } from 'app/segment/segment.service';
import { FormWeb } from 'app/shared/model/form.model';
import { FieldWeb } from 'app/shared/model/item.model';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { FormValidationService } from 'app/shared/service/form-validation.service';
import { LogicRuleService } from 'app/shared/service/logic-rules.service';
import { MetaDataService } from 'app/shared/service/meta-data.service';
import { ProjectService } from 'app/shared/service/project.service';
import { RouteParamsService } from 'app/shared/service/route-params.service';
import { TeamService } from 'app/shared/service/team.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { ProfileService } from 'app/user/profile.service';
import { UserService } from 'app/user/user.service';

import { AddEditFormBaseComponent } from '../add-edit-form-base.component';

@Component({
  selector: 'cc-preview-form',
  templateUrl: '../edit-form.component.html',
  styleUrls: [
    '../edit-form.component.scss',
    '../../../../styles/legacy/app-modal.scss',
    '../../form-components/form-component.scss',
  ],
})
export class PreviewFormComponent extends AddEditFormBaseComponent implements OnInit, OnDestroy {
  canDelete = false;
  isCompanyLibrary: boolean;
  templateId: string;

  @Input() editorTemplateId: string;
  @Input() fields: Field[] = [];
  @Input() attachments: FormAttachment[] = [];
  @Input() formulasVersion: FormulasVersions;
  @Input() templateOrientation: TemplateOrientations;
  @Input() counterIsActivated: boolean;
  @Input() templateUniqueAppId: string;
  @Input() headerFields: HeaderFooterField[];

  private unSubscribe = new Subject<void>();

  constructor(
    protected readonly activeModal: NgbActiveModal,
    protected readonly toastr: ToastrService,
    protected readonly modal: NgbModal,
    protected readonly i18nService: TmpI18NService,
    protected readonly confirmService: ConfirmService,
    protected readonly formService: FormService,
    protected readonly errorHandler: ErrorHandler,
    protected readonly listsService: ListsService,
    protected readonly location: Location,
    protected readonly platformLocation: PlatformLocation,
    protected readonly metaDataService: MetaDataService,
    protected readonly profileService: ProfileService,
    protected readonly projectService: ProjectService,
    protected readonly segmentService: SegmentService,
    protected readonly userService: UserService,
    protected readonly formValidationService: FormValidationService,
    protected readonly logicRuleService: LogicRuleService,
    protected readonly hostElement: ElementRef,
    private readonly teamService: TeamService,
    protected readonly appUtilService: AppUtilService,
    private readonly routeParamsService: RouteParamsService,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly templatesService: TemplatesService,
    protected readonly injector: Injector,
    protected readonly formEventsService: FormEventsService,
    protected readonly samService: SamService,
  ) {
    super(
      location,
      platformLocation,
      activeModal,
      i18nService,
      listsService,
      errorHandler,
      toastr,
      confirmService,
      formService,
      modal,
      metaDataService,
      profileService,
      segmentService,
      userService,
      formValidationService,
      logicRuleService,
      hostElement,
      changeDetectorRef,
      templatesService,
      injector,
      formEventsService,
      appUtilService,
      samService,
    );
  }

  ngOnInit(): void {
    super.ngOnInit();
    this.initializePreview();
    this.initializeHeaderSettingsPreview(this.headerFields);
  }

  initializeHeaderSettingsPreview(headerFields?: HeaderFooterField[]) {
    this.headerFields = headerFields?.length
      ? headerFields.filter((field) => field.isVisible !== false)
      : HeaderFooterDefaultFields.header;
    this.isHeaderSettingsLoaded = true;
  }

  private initializePreview() {
    this.routeParamsService
      .get('company')
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((company) => {
        const companyId = company ? company.id : this.userService.getCurrentUser().companyControllerOf[0];
        this.initializePreviewWithCompanyId(companyId);
      });
  }

  private initializePreviewWithCompanyId(companyId: string) {
    const team = this.teamService.getCurrentTeam();
    const teamId = team?.id;
    const templateId = this.editorTemplateId || this.templateId;
    const templatePreviewConfig: ITemplatePreviewConfig = {
      orientation: this.templateOrientation,
      counterIsActivated: this.counterIsActivated,
      uniqueAppId: this.templateUniqueAppId,
      name: this.templateName,
    };

    if (this.isCompanyLibrary) {
      this.initializeCompanyFormPreview(companyId, templateId, templatePreviewConfig);
    } else {
      this.initializeGenericFormPreview(companyId, templateId, teamId, templatePreviewConfig);
    }
  }

  private initializeGenericFormPreview(
    companyId: string,
    templateId: string,
    teamId: string,
    templatePreviewConfig: ITemplatePreviewConfig,
  ) {
    const projectId = this.teamService.getCurrentTeam()?.projectId;
    const previewFormParams: IPreviewFormParams = {
      templateId,
      companyId,
      projectId,
      formulasVersion: this.formulasVersion,
      teamId,
      fields: this.fields,
      attachments: this.attachments,
      templatePreviewConfig,
    };

    this.formService
      .previewForm(previewFormParams)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(
        (form) => {
          this.setupForm(form);
        },
        (error) => this.errorHandler.handle(error),
      );
  }

  private initializeCompanyFormPreview(
    companyId: string,
    templateId: string,
    templatePreviewConfig: ITemplatePreviewConfig,
  ) {
    const previewCompanyFormParams: IPreviewCompanyFormParams = {
      templateId,
      companyId,
      formulasVersion: this.formulasVersion,
      fields: this.fields,
      attachments: this.attachments,
      templatePreviewConfig,
    };

    this.formService
      .previewCompanyForm(previewCompanyFormParams)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe(
        (form) => {
          this.setupForm(form);
        },
        (error) => this.errorHandler.handle(error),
      );
  }

  setupForm(form: FormWeb) {
    const previewForm = form;
    previewForm.isPreview = true;

    super.setupForm(previewForm);
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
    this.formulaEngineService.destroy();
  }

  onUseThisTemplate() {
    this.activeModal.close();
  }

  onClose() {
    this.activeModal.dismiss();
  }

  beforeDismiss() {
    return true;
  }

  handleNormalSign(_model: FieldWeb): Promise<void> {
    throw new Error('Not implemented');
  }

  handleApprovalSign(_model: FieldWeb): Promise<void> {
    throw new Error('Not implemented');
  }

  handleManualSign(_model: FieldWeb): Promise<void> {
    throw new Error('Not implemented');
  }
}
