export enum FormModalAction {
  Add = 'add',
  Save = 'save',
  Clone = 'clone',
  Delete = 'delete',
  Close = 'close',
  Sign = 'sign',
  ResetSignature = 'resetSignature',
  Customise = 'customise',
}
