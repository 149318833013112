import { Injectable } from '@angular/core';

import { FieldKinds, FormFieldValidatorFactory } from '@site-mate/dashpivot-shared-library';

import { FormWeb } from 'app/shared/model/form.model';
import { FieldWeb } from 'app/shared/model/item.model';
import { LogicRuleService } from 'app/shared/service/logic-rules.service';

@Injectable({
  providedIn: 'root',
})
export class FormValidationService {
  constructor(private readonly logicRuleService: LogicRuleService) {}

  isWorkflow(form: FormWeb) {
    return form.columns.length > 0;
  }

  fieldSigned(item: FieldWeb) {
    const { kind, signedOn, userId, fullName, signatureUrl } = item;

    return kind === FieldKinds.Signature && signedOn && !!signatureUrl && !!userId && !!fullName;
  }

  calculateWorkflowColumn(items: FieldWeb[]) {
    // Calculate the number of approval signatures
    return items.reduce((signed, item) => (this.fieldSigned(item) ? signed + 1 : signed), 1);
  }

  isInLastColumn(form: FormWeb) {
    return this.calculateWorkflowColumn(form.items as FieldWeb[]) === form.columns.length;
  }

  isValid(form: FormWeb, ignoreRequiredValidation?: boolean) {
    if (this.isWorkflow(form) && !this.isInLastColumn(form)) {
      return true;
    }

    const formItems = ignoreRequiredValidation
      ? this.getFieldsWithIgnoredRequiredValidation(form.items as FieldWeb[])
      : form.items;

    const visibleFields = this.logicRuleService.getVisibleFields(formItems);
    this.validateFields(visibleFields);
    return this.areAllFieldsValid(form.items);
  }

  private getFieldsWithIgnoredRequiredValidation(fields: FieldWeb[]): FieldWeb[] {
    return fields.map((field) => {
      delete field._invalid;
      return { ...field, isRequired: false };
    });
  }

  isValidForSigning(form: FormWeb, signatureId: string) {
    const signatureIndex = form.items.findIndex((field) => field.id === signatureId);
    const fieldsBeforeSignature = form.items.slice(0, signatureIndex);

    // all prior fields need to be valid to Sign
    const visibleFieldsBeforeSignature = this.logicRuleService.getVisibleFields(fieldsBeforeSignature);
    this.validateFields(visibleFieldsBeforeSignature);
    return this.areAllFieldsValid(visibleFieldsBeforeSignature);
  }

  private areAllFieldsValid(fields: FieldWeb[]): boolean {
    return !fields.some((field) => !!field._invalid);
  }

  private validateFields(fields: FieldWeb[]) {
    fields.forEach((formField) => {
      const validator = FormFieldValidatorFactory.getValidator(formField);
      formField._invalid = !validator.isValid(formField);
    });
  }
}
