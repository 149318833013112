<div class="import-modal">
  <div class="import-modal-header">
    <h4 class="sm-text-xl sm-font-medium" i18n>Import</h4>
    <button type="button" (click)="close()" class="sm-ml-auto hover:sm-cursor-pointer">
      <i class="cc-icon cc-icon-close sm-align-middle"></i>
    </button>
  </div>
  <div class="import-modal-body">
    <div>
      <p>
        <img
          src="assets/icons/multi-colored-icons/sam-square.svg"
          class="sm-inline"
          alt="sam-logo"
          data-test="sam-logo"
        />
        <span i18n>Sam will automatically convert your dockets into rows in the table.</span>
      </p>
      <p class="sm-mt-4" i18n>What kinds of dockets would you like to import?</p>
    </div>
    <div class="sm-mt-4 sm-flex sm-gap-4 sm-font-medium">
      <button type="button" (click)="onPhotoSelected()" class="docket-import-type">
        <div class="sm-flex sm-flex-1">
          <img src="./assets/images/sam/docket-photos.svg" alt="docket-photos" data-test="docket-photos" />
        </div>
        <div class="sm-mt-2" i18n>Docket Photos</div>
      </button>
      <button type="button" class="docket-import-type" (click)="onPDFSelected()">
        <div class="sm-flex sm-flex-1">
          <img src="./assets/images/sam/docket-pdfs.svg" alt="docket-pdfs" data-test="docket-pdfs" />
        </div>
        <div class="sm-mt-2" i18n>Docket PDF</div>
      </button>
    </div>
  </div>
</div>
