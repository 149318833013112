<div class="form-header">
  <ng-container *ngFor="let field of draggableFields">
    <!-- Logo -->
    <div class="logo-container" *ngIf="field.kind === headerFooterKind.Logo" [ngClass]="field.gridClasses">
      <cc-edit-modal-logo [company]="company"></cc-edit-modal-logo>
    </div>
    <!-- File Path -->
    <div class="form-filepath" *ngIf="field.kind === headerFooterKind.Filepath" [ngClass]="field.gridClasses">
      <p class="sm-line-clamp-3 sm-break-words"><strong i18n>Filepath: </strong>{{ filePath }}</p>
    </div>
    <!-- Template ID  -->
    <p
      data-test="template-id"
      class="sm-truncate"
      *ngIf="field.kind === headerFooterKind.TemplateId"
      [ngClass]="field.gridClasses"
    >
      <strong i18n>Template ID: </strong>
      <span data-test="template-id">{{ template?.uniqueAppId || '-' }} </span>
    </p>
    <!-- Template Version -->
    <p
      data-test="template-version"
      *ngIf="field.kind === headerFooterKind.TemplateVersion"
      [ngClass]="field.gridClasses"
    >
      <strong i18n>Template Version: </strong>{{ 'v' + template?.appVersion || '-' }}
    </p>
    <!-- Form Version -->
    <p
      data-test="form-version"
      *ngIf="field.kind === headerFooterKind.FormVersion"
      [ngClass]="field.gridClasses"
    >
      <strong i18n>Form Version: </strong>{{ form.formVersion ? 'v' + form.formVersion : '-' }}
    </p>
    <!-- Prefilled Text -->
    <p
      data-test="prefilled-text"
      *ngIf="field.kind === headerFooterKind.PrefilledText"
      class="sm-truncate"
      [class]="field.gridClasses"
      i18n
    >
      {{ field.content }}
    </p>
    <!-- Uncontrolled Print Version -->
    <p
      *ngIf="field.kind === headerFooterKind.UncontrolledVersionDisclaimer"
      [ngClass]="field.gridClasses"
      i18n
    >
      Printed version is uncontrolled
    </p>
    <!-- Page Numbers -->
    <p *ngIf="field.kind === headerFooterKind.PageNumbers" [ngClass]="field.gridClasses" i18n>Page # of #</p>
    <!-- Pdf Creation Time And Date -->
    <p *ngIf="field.kind === headerFooterKind.PdfCreationTimeAndDate" [ngClass]="field.gridClasses" i18n>
      This PDF was created at <br />[DDD, DD MMM YYYY HH:MM AM]
    </p>
  </ng-container>
</div>
<!-- Brand Color Line -->
<hr class="title-color-line" [style.background-color]="company?.colorCode" />
<div class="with-dark-popover top-adjustment" [class.title-holder]="templateNameField || formCreatorField">
  <!-- Template Name -->
  <h2 *ngIf="templateNameField" class="template-name">
    {{ template?.name }}
  </h2>
  <div class="sm-flex-grow"></div>
  <!-- Author Data -->
  <cc-author-data [isEditForm]="isEditForm" [form]="form" *ngIf="formCreatorField"></cc-author-data>
</div>
