import { FieldKinds, IDatePlainField } from '@site-mate/dashpivot-shared-library';
import { IDateRangePlainField } from '@site-mate/dashpivot-shared-library/dist/models/fields/date-range-plain.model';

import { FieldWeb } from 'app/shared/model/item.model';

import { FormItemBaseChangeDetector } from './form-item-change-detector-base';
import { FormDateUtil } from '../form-date-util.service';

export class DatePlainChangeDetector extends FormItemBaseChangeDetector {
  hasChanged(newItem: FieldWeb, oldItem: FieldWeb): boolean {
    if (newItem.kind === FieldKinds.DatePlain) {
      return !FormDateUtil.isDateEqual(
        (newItem as IDatePlainField).data.date,
        (oldItem as IDatePlainField).data.date,
      );
    }
    if (newItem.kind === FieldKinds.DateRangePlain) {
      return (
        !FormDateUtil.isDateEqual(
          (newItem as IDateRangePlainField).data.date,
          (oldItem as IDateRangePlainField).data.date,
        ) ||
        !FormDateUtil.isDateEqual(
          (newItem as IDateRangePlainField).data.dateEnd,
          (oldItem as IDateRangePlainField).data.dateEnd,
        )
      );
    }
    throw new Error('Unsupported field kind usage in DatePlainChangeDetector.hasChanged');
  }
}
