import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import {
  Company,
  HeaderFooterField,
  HeaderFooterFieldKinds,
  IHeaderFooterGrid,
  Template,
} from '@site-mate/dashpivot-shared-library';

import { FormWeb } from 'app/shared/model/form.model';

import { IHeaderFooterFieldGrid } from './header-footer-field-grid.model';

@Component({
  selector: 'cc-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss', './grid-item-layout.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormHeaderComponent implements OnInit {
  @Input() company: Company;
  @Input() filePath: string;
  @Input() template: Template;
  @Input() isEditForm: boolean;
  @Input() form: FormWeb;

  @Input() headerFields: HeaderFooterField[] = [];

  draggableFields: IHeaderFooterFieldGrid[] = [];
  fixedFields: HeaderFooterField[] = [];
  templateNameField: HeaderFooterField;
  formCreatorField: HeaderFooterField;

  readonly headerFooterKind = HeaderFooterFieldKinds;

  ngOnInit(): void {
    this.setDraggableFields();
    this.setTemplateNameField();
    this.setFormCreatorField();
  }

  private setDraggableFields() {
    this.draggableFields = this.headerFields
      .filter((field) => field.gridPosition)
      .map((field) => {
        const gridClasses = this.addGridClassesToElement(field.gridPosition);
        return { ...field, gridClasses };
      });
  }

  private setTemplateNameField() {
    this.templateNameField = this.headerFields.find(
      (field) => field.kind === HeaderFooterFieldKinds.TemplateName,
    );
  }

  private setFormCreatorField() {
    this.formCreatorField = this.headerFields.find(
      (field) => field.kind === HeaderFooterFieldKinds.FormCreatorAndCreationTime,
    );
  }

  private addGridClassesToElement(gridPosition: IHeaderFooterGrid) {
    const columnEnd = Number(gridPosition.column) + Number(gridPosition.columnSpan);
    const rowEnd = Number(gridPosition.row) + Number(gridPosition.rowSpan);

    return `grid-col-start-${gridPosition.column} grid-row-start-${gridPosition.row} grid-col-end-${columnEnd} grid-row-end-${rowEnd}`;
  }
}
