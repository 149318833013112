import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { cloneDeep, isEqual } from 'lodash-es';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import {
  CategoryTypes,
  EventTypes,
  FieldItem,
  FieldKinds,
  FormFieldValidatorFactory,
  StyleVariants,
} from '@site-mate/dashpivot-shared-library';

import { FormBaseComponent } from 'app/form/form-components/form-base.component';
import { FormService } from 'app/form/form.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { FormCategoryOption } from 'app/shared/model/form-category.option.model';
import { FieldWeb, LogicRuleWeb } from 'app/shared/model/item.model';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { EventsService } from 'app/shared/service/events/events.service';
import { LogicRuleService } from 'app/shared/service/logic-rules.service';

import { FormCategoryService } from './form-category.service';

@Component({
  selector: 'cc-form-category',
  templateUrl: 'form-category.component.html',
  styleUrls: [
    'form-category.component.scss',
    './form-category-general-style.scss',
    '../../../form/form-components/form-component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormCategoryComponent extends FormBaseComponent<FieldWeb> implements OnInit, OnDestroy {
  private readonly unSubscribe = new Subject<void>();

  @Output() resetFields = new EventEmitter();

  @Input() metadataList;
  @Input() parentId: string;
  @Input() invalid: string;

  categoryTypes = CategoryTypes;
  fieldKinds = FieldKinds;
  categoryOptions: FormCategoryOption[];
  prevFields: FieldItem[];
  selectedOption;
  hasLogicRules = false;

  readonly styleVariants = StyleVariants;

  constructor(
    protected readonly appUtilService: AppUtilService,
    protected readonly formService: FormService,
    private readonly eventsService: EventsService,
    private readonly formCategoryService: FormCategoryService,
    private readonly logicRuleService: LogicRuleService,
    private readonly ref: ChangeDetectorRef,
    private readonly confirmService: ConfirmService,
    private readonly i18nService: TmpI18NService,
  ) {
    super(appUtilService, formService);
  }

  ngOnInit(): void {
    this.validator = FormFieldValidatorFactory.getValidator(this.model);

    if (this.model.kind !== FieldKinds.Category) {
      return;
    }

    this.model.selectedItems = this.model.selectedItems || [];
    if (this.model.categoryType === CategoryTypes.Dropdown) {
      this.selectedOption = this.model.selectedItems[0];
    }

    this.model._dirty = !!this.model._dirty;
    this.prevFields = [...this.model.selectedItems];
    this.hasLogicRules = this.logicRuleService.hasLogicRules(this.model);
    this.loadCategoryItems();
  }

  private loadCategoryItems() {
    this.formCategoryService
      .getCategoryItems(this.model as FieldWeb, this.metadataList, this.parentId)
      .pipe(takeUntil(this.unSubscribe))
      .subscribe((fields) => {
        this.categoryOptions = fields;
        this.ref.detectChanges();
      });
  }

  onDropdownSelect(selectedOption: FormCategoryOption) {
    this.selectDropdownFields(selectedOption);
    if (this.hasLogicRules) {
      this.handleChangeLogicRules(this.rollbackCategoryDropdownChange.bind(this));
    } else {
      this.updatePropertiesOnChange();
    }
  }

  private selectDropdownFields(selectedOption: FormCategoryOption) {
    if (selectedOption) {
      this.eventsService.trackEvent(EventTypes.FormCategoryAppliedInForm, { Context: this.context });
      if (selectedOption.isDeployed) {
        this.eventsService.trackEvent(EventTypes.ListParentItemAddedToChild, { Context: this.context });
      }

      this.model.selectedItems[0] = selectedOption as unknown as FieldItem;
    } else {
      this.model.selectedItems = [];
    }
  }

  onToggleSelected(selectedOption: FormCategoryOption) {
    this.selectToggleFields(selectedOption);

    if (this.hasLogicRules) {
      this.handleChangeLogicRules(this.rollbackCategoryChange.bind(this));
    } else {
      this.updatePropertiesOnChange();
    }
  }

  private selectToggleFields(selectedOption: FormCategoryOption) {
    if (this.isSelected(selectedOption)) {
      this.model.selectedItems = this.model.selectedItems.filter(
        (selectedField) => selectedField.id !== selectedOption.id,
      );
    } else {
      this.model.selectedItems = [...this.model.selectedItems, selectedOption as FieldItem];

      this.eventsService.trackEvent(EventTypes.FormCategoryAppliedInForm, { Context: this.context });
      if (selectedOption.isDeployed) {
        this.eventsService.trackEvent(EventTypes.ListParentItemAddedToChild, { Context: this.context });
      }
    }
  }

  handleChangeLogicRules(rollbackChangeFn) {
    const closingLogicRules = this.logicRuleService.filterLogicRuleWhichAreGoingToClose(this.model);
    if (this.logicRuleService.logicRulesSubFieldsHaveData(closingLogicRules)) {
      // eslint-disable-next-line promise/catch-or-return
      this.confirmService
        .confirm('formLogicChangeConfirmation', {
          confirmButtonText: this.i18nService.getMessage('confirmConfirm'),
        })
        // eslint-disable-next-line promise/always-return
        .then(() => {
          this.updateLogicRules(closingLogicRules);
        })
        .catch(() => rollbackChangeFn())
        .finally(() => this.ref.detectChanges());
    } else {
      this.updateLogicRules(closingLogicRules);
    }
  }

  private resetLogicRulesFields(logicRules) {
    this.resetFields.emit(logicRules.reduce((fieldsToReset, rule) => [...fieldsToReset, ...rule.fields], []));
  }

  private updateLogicRules(closingLogicRules: LogicRuleWeb[]) {
    const originalRules = cloneDeep(this.model.logicRules);
    this.prevFields = [...this.model.selectedItems];
    this.model.logicRules = this.logicRuleService.sharedService.evaluate(this.model);
    this.trackLogicRuleEvent(originalRules);
    this.resetLogicRulesFields(closingLogicRules);
    this.updatePropertiesOnChange();
  }

  private rollbackCategoryChange() {
    this.model.selectedItems = [...this.prevFields];
  }

  private rollbackCategoryDropdownChange() {
    this.model.selectedItems = [...this.prevFields];
    this.selectedOption = this.prevFields[0];
  }

  private trackLogicRuleEvent(originalRules) {
    if (!isEqual(this.model.logicRules, originalRules)) {
      this.eventsService.trackEvent(EventTypes.TemplateLogicExecuted, { Context: this.context });
    }
  }

  private updatePropertiesOnChange() {
    this.model._dirty = true;
    this.model._invalid = !this.validator.isValid(this.model);
  }

  get hasNoSelection(): boolean {
    return !!(this.categoryOptions && !this.categoryOptions.length);
  }

  getSelectedStyle(field: FormCategoryOption) {
    if (this.isSelected(field)) {
      if (field.style) {
        return `${field.style} category-item-selected`;
      }
      return `${this.styleVariants.None} category-item-selected`;
    }

    return field.style || this.styleVariants.None;
  }

  isSelected(item: FormCategoryOption) {
    return this.model?.selectedItems?.some((i) => i.id === item.id);
  }

  private get context() {
    const fieldContext = {
      [CategoryTypes.Dropdown]: 'List Dropdown',
      [CategoryTypes.Inline]: 'List Multichoice',
    };

    return fieldContext[this.model.categoryType];
  }

  ngOnDestroy(): void {
    this.unSubscribe.next();
    this.unSubscribe.complete();
  }
}
