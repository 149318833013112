import {
  ChangeDetectorRef,
  Component,
  OnInit,
  ChangeDetectionStrategy,
  EventEmitter,
  Output,
} from '@angular/core';
import { cloneDeep, isEqual } from 'lodash-es';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  FormFieldValidator,
  FormFieldValidatorFactory,
  YesNoValues,
} from '@site-mate/dashpivot-shared-library';

import { FormService } from 'app/form/form.service';
import { EditFormFieldComponent } from 'app/form-fields/common/edit/edit-form-field.component';
import { SegmentService } from 'app/segment/segment.service';
import { FieldWeb, LogicRuleWeb } from 'app/shared/model/item.model';
import { ConfirmService } from 'app/shared/service/confirm.service';
import { LogicRuleService } from 'app/shared/service/logic-rules.service';

import { TmpI18NService } from '../../../i18n/tmp-i18n.service';

@Component({
  selector: 'cc-yes-no-edit',
  templateUrl: './yes-no-edit.component.html',
  styleUrls: ['./yes-no-edit.component.scss', '../../../form/form-components/form-component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class YesNoEditComponent implements EditFormFieldComponent, OnInit {
  @Output() resetFields = new EventEmitter();

  fieldData: any;
  model: FieldWeb;
  validator: FormFieldValidator;
  prevValue: YesNoValues;
  hasLogicRules = false;
  dependsOnAnotherField = false;

  readonly values = YesNoValues;

  constructor(
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly formService: FormService,
    private readonly segmentService: SegmentService,
    private readonly logicRuleService: LogicRuleService,
    private readonly confirmService: ConfirmService,
    private readonly i18nService: TmpI18NService,
  ) {}

  ngOnInit(): void {
    this.model = this.fieldData.model;
    this.validator = FormFieldValidatorFactory.getValidator(this.model);
    this.hasLogicRules = this.logicRuleService.hasLogicRules(this.model);
    this.prevValue = this.model.checked;
    this.dependsOnAnotherField = this.logicRuleService.dependsOnAnotherField(this.model);
  }

  onChange() {
    this.model._dirty = true;
    this.model._invalid = !this.validator.isValid(this.model);
    this.changeDetectorRef.markForCheck();
  }

  onFieldChange() {
    this.formService.onFieldChanged.next();
  }

  onChangeModel() {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.FormCheckboxValueApplied, { Context: this.context }),
      this.segmentService,
    );
    this.onFieldChange();
    if (this.hasLogicRules) {
      this.onChangeLogicRuleField();
    } else {
      this.onChange();
    }
  }

  onChangeLogicRuleField() {
    const closingLogicRules = this.logicRuleService.filterLogicRuleWhichAreGoingToClose(this.model);

    if (this.logicRuleService.logicRulesSubFieldsHaveData(closingLogicRules)) {
      this.confirmService
        .confirm('formLogicChangeConfirmation', {
          confirmButtonText: this.i18nService.getMessage('confirmConfirm'),
        })
        .then(() => {
          this.updateLogicRules(closingLogicRules);
          return true;
        })
        .catch(() => {
          this.rollbackLastChange();
        });
    } else {
      this.updateLogicRules(closingLogicRules);
    }
  }

  private updateLogicRules(closingLogicRules: LogicRuleWeb[]) {
    const originalRules = cloneDeep(this.model.logicRules);
    this.prevValue = this.model.checked;
    this.model.logicRules = this.logicRuleService.sharedService.evaluate(this.model);
    this.afterLogicRuleUpdate(originalRules);
    this.resetLogicRulesFields(closingLogicRules);
    this.onChange();
  }

  private resetLogicRulesFields(logicRules) {
    this.resetFields.emit(logicRules.reduce((fieldsToReset, rule) => [...fieldsToReset, ...rule.fields], []));
  }

  private rollbackLastChange() {
    this.model.checked = this.prevValue;
  }

  private afterLogicRuleUpdate(originalRules) {
    if (!isEqual(this.model.logicRules, originalRules)) {
      void EventNotifierService.notify(
        new DashpivotEvent(EventTypes.TemplateLogicExecuted, { Context: this.context }),
        this.segmentService,
      );
    }
  }

  get context() {
    return `YesNo${this.model.hasNaButton ? 'NA' : ''}${this.model.hasTextInput ? 'Text' : ''}`;
  }
}
