import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ConfirmService } from 'app/shared/service/confirm.service';

@Component({
  selector: 'cc-form-sketch-modal',
  templateUrl: 'form-sketch-modal.component.html',
})
export class FormSketchModalComponent {
  model;

  constructor(
    public modal: NgbActiveModal,
    private confirmService: ConfirmService,
  ) {}

  dismiss() {
    const json = JSON.stringify(this.model.canvasObj.toJSON());
    if (this.model.canvas !== json) {
      void this.confirmService
        .confirmSave('saveChangeBeforeClose')
        .then(() => {
          this.modal.close();
          return true;
        })
        .catch((dismiss) => {
          if (dismiss === 'cancel') {
            this.modal.dismiss();
          }
        });
    } else {
      this.modal.dismiss();
    }
  }
}
