import { PhotoComparisonUtils } from 'app/form-fields/photo/photo-comparison-utils';
import { FieldWeb } from 'app/shared/model/item.model';

import { FormItemBaseChangeDetector } from './form-item-change-detector-base';

export class PhotoChangeDetector extends FormItemBaseChangeDetector {
  hasChanged(newItem: FieldWeb, oldItem: FieldWeb): boolean {
    return this.hasPhotoChanged(newItem, oldItem);
  }

  private hasPhotoChanged(newItem: FieldWeb, oldItem: FieldWeb): boolean {
    return PhotoComparisonUtils.hasPhotoChanged(newItem, oldItem);
  }
}
