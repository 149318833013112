import { Injectable } from '@angular/core';
import { tap } from 'rxjs';

import { EventTypes } from '@site-mate/dashpivot-shared-library';

import { GlobalApiService } from 'app/global-api/global-api.service';
import { dayjs } from 'app/shared/dayjs';
import { FieldWeb } from 'app/shared/model/item.model';

import { SamImportFileType } from './model/sam-import-file-type.enum';
import { SamEventsService } from './sam-events.service';

@Injectable({
  providedIn: 'root',
})
export class SamService {
  constructor(
    private readonly sitemateGlobalApiService: GlobalApiService,
    private readonly samEventsService: SamEventsService,
  ) {}

  listTasks(appId: string) {
    return this.sitemateGlobalApiService.listSamTasks(appId);
  }

  executeTask(taskId: string, formItem: FieldWeb, files: { url: string; type: SamImportFileType }[]) {
    const context = 'Docket Import';

    return this.sitemateGlobalApiService
      .executeSamTask(taskId, {
        formItem,
        files,
        timeZone: dayjs.tz.guess(),
      })
      .pipe(
        tap((taskExecution) => {
          this.samEventsService.trackEvent(EventTypes.SamTaskExecuted, {
            Context: context,
          });
          return taskExecution;
        }),
      );
  }
}
