import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

import {
  FieldKinds,
  FormFieldValidatorFactory,
  IHierarchy,
  ITemplateWithHierarchy,
  TableFormFieldValidator,
} from '@site-mate/dashpivot-shared-library';

import { FormBaseComponent } from 'app/form/form-components/form-base.component';
import { FormService } from 'app/form/form.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { ITaskExecution } from 'app/sam/model/task-execution.model';
import { TaskType } from 'app/sam/model/task-type.enum';
import { BannerKeys } from 'app/shared/model/banner-keys.enum';
import { BannerMetadata } from 'app/shared/model/banner-metadata.model';
import { FieldWeb } from 'app/shared/model/item.model';
import { AppUtilService } from 'app/shared/service/app-util.service';

import { TableColumnService } from '../service/table-column-service';

@Component({
  selector: 'cc-form-table',
  templateUrl: 'form-table.component.html',
  styleUrls: ['../../../form/form-components/form-component.scss', 'form-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTableComponent extends FormBaseComponent<FieldWeb> implements OnInit {
  readonly bannerMetadata = BannerMetadata;
  readonly bannerKeys = BannerKeys;

  @Input() template: ITemplateWithHierarchy;
  @Input() formId: string;
  @Input() formPath: string;
  @Input() isReadOnly: boolean;
  @Input() parentId: string | undefined;
  @Input() invalid;
  @Input() isLandscapeMode: boolean;
  @Input() hierarchy: IHierarchy;

  @Output() invalidTable = new EventEmitter();

  isPrefilledTable = false;
  isSamDocketImportEnabled = false;
  showTipActionBanner = false;
  labelClass = '';

  readonly descriptions = {
    import: this.i18nService.getMessage(
      /firefox|edge/i.test(String(navigator.userAgent))
        ? 'importByPasteDescriptionBrowserDefinedFormats'
        : 'importByPasteDescription',
    ),
    keyboardShortcuts: this.i18nService.getMessage('tableKeyboardShortcuts'),
  };

  constructor(
    protected readonly appUtilService: AppUtilService,
    protected readonly formService: FormService,
    protected readonly i18nService: TmpI18NService,
    private readonly tableColumnService: TableColumnService,
  ) {
    super(appUtilService, formService);
  }

  ngOnInit(): void {
    this.validator = FormFieldValidatorFactory.getValidator(this.model);
    this.isPrefilledTable = this.model.kind === FieldKinds.PrefilledTable;

    const isTemplateValid = this.template && this.template.items;
    this.showTipActionBanner = isTemplateValid && this.isTipActionBannerVisible();
    this.isSamDocketImportEnabled = this.model.samTask && this.model.samTask.type === TaskType.docketImport;

    if (this.model.isHiddenInFormPDFExports) {
      this.labelClass = 'hidden-field-label';
    }
  }

  isTipActionBannerVisible(): boolean {
    const formFieldItems = this.template.items;
    const tableFieldItems = formFieldItems.filter(
      (field) => field.kind === FieldKinds.PrefilledTable || field.kind === FieldKinds.Table,
    );

    const currentFieldId = this.model._id;
    const isCurrentFieldFirstTableField =
      tableFieldItems.findIndex((item) => item._id === currentFieldId) === 0;

    const isInWorkflowReadonly = this.model.readOnly;
    if (isCurrentFieldFirstTableField && !isInWorkflowReadonly) {
      return true;
    }

    return false;
  }

  addRow() {
    this.tableColumnService.addRowToTable(this.model.id);
  }

  onSamResponseReceived({ result }: ITaskExecution) {
    if ((this.validator as TableFormFieldValidator).tableHasOneEmptyRow(this.model)) {
      this.model.rows = [];
    }

    const { _id: tableId, rows } = result;
    this.tableColumnService.batchAddRows({
      tableId,
      newRows: rows,
    });
    this.model.samMetadata = this.model.samMetadata
      ? { ...this.model.samMetadata, executed: true }
      : { executed: true };
  }

  validateTable() {
    this.model._invalid = !this.validator.isValid(this.model);
  }
}
