<div *ngIf="model.kind === fieldKinds.Person">
  <div class="form-group row {{ model.kind }}" [class.invalid-field]="model._invalid">
    <div class="row col-12">
      <cc-form-field-label [model]="model" class="col-3 form-label" [label]="model.description" />
      <div class="col-9 sm-flex">
        <div class="form-inline sm-flex-auto">
          <ng-container *ngIf="selectedOption?.archived; else noToolTip">
            <div class="form-inline" [gwTooltip]="archivedUserNotice" gwTooltipIndicatorPosition="left">
              <ng-container *ngTemplateOutlet="dropdownTemplate"></ng-container>
            </div>
          </ng-container>
          <span
            *ngIf="!loading && !error && unknownUser"
            class="sm-flex sm-text-xs sm-text-default-red"
            data-test="missing-user-warning"
            i18n
          >
            <i class="cc-icon cc-icon-warning"></i> Unable to detect a selected user. Please select a new
            user.
          </span>
          <div class="row">
            <div *ngIf="model._invalid" class="col sm-text-default-red" i18n>
              {{ invalidDescription() }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noToolTip>
  <ng-container *ngTemplateOutlet="dropdownTemplate"></ng-container>
</ng-template>

<ng-template #dropdownTemplate>
  <cc-search-and-select
    name="person-list"
    class="sm-w-[200px]"
    [class.sm-mr-2]="unknownUser"
    [items]="personOptions$ | async"
    [groupBy]="groupByFn"
    [selected]="error ? null : selectedOption"
    [notFoundText]="'No users found'"
    [multiple]="false"
    [clearable]="true"
    [searchable]="true"
    [closeOnSelect]="true"
    [disabled]="model.readOnly"
    [loading]="loading || error"
    [invalid]="unknownUser"
    [placeholder]="'Select...'"
    (selectedChange)="onDropdownSelect($event); onFieldChange()"
  >
    <ng-template #customOptionTemplate let-option>
      <ng-container [ngTemplateOutlet]="userOptionTemplate" [ngTemplateOutletContext]="{ $implicit: option }">
      </ng-container>
    </ng-template>

    <ng-template #customLabelTemplate let-user let-clear>
      <ng-container *ngIf="!unknownUser">
        <span class="ng-value-label sm-flex sm-items-center">
          <i *ngIf="user.icon" class="cc-icon {{ user.icon }} sm-mr-1"></i>
          <cc-user-avatar
            [user]="user"
            [parseAvatarUrl]="!!folderId"
            class="small-avatar sm-mr-1"
          ></cc-user-avatar>
          <span class="sm-overflow-hidden sm-text-ellipsis" data-test="selected-option">{{
            user.fullName
          }}</span>
        </span>
        <span class="ng-value-icon" (click)="clear(user)" onKeyDown="clear(user)" aria-hidden="true">
          <i class="cc-icon cc-icon-close"></i>
        </span>
      </ng-container>
    </ng-template>

    <ng-template #customLoadingTemplate let-searchTerm>
      <div class="ng-option-loading sm-flex sm-p-2" data-test="person-loading-template">
        <i class="cc-icon cc-icon-spinner-sm"></i>
        <span class="sm-ml-1 sm-align-top sm-text-secondary-text">Loading list of users...</span>
      </div>
    </ng-template>
  </cc-search-and-select>
</ng-template>

<ng-template #userOptionTemplate let-option>
  <div class="sm-flex sm-items-center">
    <i *ngIf="option.icon" class="cc-icon {{ option.icon }} sm-mr-1"></i>
    <cc-user-avatar
      *ngIf="!option.icon"
      [user]="option"
      [parseAvatarUrl]="!!folderId"
      class="small-avatar sm-mr-1"
    ></cc-user-avatar>
    <span class="sm-overflow-hidden sm-text-ellipsis">{{ option.label }}</span>
    <span *ngIf="option.email === (currentUser | async).email" class="current-user-label">(You)</span>
  </div>
</ng-template>
