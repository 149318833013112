import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { defaultTo, uniqBy } from 'lodash-es';
import moment from 'moment-timezone';

import {
  List,
  ListEntryTypes,
  ListProperty,
  ListService,
  StyleVariants,
} from '@site-mate/dashpivot-shared-library';

const { tz } = moment as any;

export class ListsHandler {
  public static service = new ListService();
  private _lists: List[] = [];

  static getTimezoneAwareProperty(date: NgbDateStruct, timezone: string) {
    return tz(
      {
        year: date.year,
        month: date.month - 1,
        day: date.day,
        hour: 0,
        minute: 0,
        second: 0,
      },
      timezone,
    ).toISOString();
  }

  static isNumber(value: string | undefined) {
    return !Number.isNaN(Number(value));
  }

  get lists() {
    return this._lists.slice();
  }

  get selectedList() {
    return this._lists.find((list) => list.selected);
  }

  addList(list: List, timezone: string) {
    const newLists = this._lists.filter((entry) => !entry.isNew && !entry.editing).concat(list);
    const visitedLists = this.visitListProperties(newLists, timezone);
    this._lists = visitedLists;
  }

  addBlankEditableList() {
    const blankEditableList = { id: String(Date.now()), isNew: true, editing: true, name: '' } as List;
    this._lists = this._lists.concat(blankEditableList);
  }

  addBlankEditableListItem() {
    const blankEditableListItem = {
      id: String(Date.now()),
      name: '',
      isNew: true,
      editing: true,
      properties: [],
      value: null,
      style: this.selectedList.defaultStyle || StyleVariants.None,
    } as List['items'][0];
    this.selectedList.items = this.selectedList.items.concat(blankEditableListItem);
  }

  updateList(id: string, list: List, timezone: string) {
    const listIndex = this._lists.findIndex((entry) => entry.id === id);
    if (listIndex !== -1) {
      list.items = this.sortItems(list.items);
      const updatedLists = this._lists.slice();
      updatedLists.splice(listIndex, 1, list);
      this._lists = updatedLists.filter((item) => !item.isNew);
      this.setSelectedList(list, timezone);
    }
  }

  deleteList(id: string, timezone: string) {
    const listIndex = this._lists.findIndex((entry) => entry.id === id);
    if (listIndex !== -1) {
      const updatedLists = this._lists.slice();
      const { selectedList } = this;
      updatedLists.splice(listIndex, 1);
      this._lists = updatedLists;
      if (selectedList && selectedList.id === id) {
        this.setSelectedList(undefined, timezone);
      }
    }
  }

  setLists(lists: List[], timezone: string) {
    this._lists = lists.map((list) => {
      list.items = this.sortItems(list.items);
      return list;
    });
    this.visitListProperties(this._lists, timezone);
  }

  setSelectedList(list: List | undefined, timezone: string) {
    const [firstEntry] = this._lists;
    this._lists.forEach((entry) => {
      entry.selected = list ? list.id === entry.id : entry === firstEntry;
    });
    this.visitListProperties(this._lists, timezone);
  }

  setSelectedListById(listId: string, timezone: string) {
    const list = this._lists.find((entry) => entry.id === listId);
    if (list) {
      list.selected = true;
      this.visitListProperties(this._lists, timezone);
    }
  }

  getStorableList(sourceList: List) {
    const sourceListItems = defaultTo(sourceList.items, []) as typeof sourceList.items;
    const sourceListProperties = defaultTo(sourceList.properties, []) as typeof sourceList.properties;
    return {
      ...sourceList,
      items: sourceListItems.slice().map((sourceItem) => {
        const item = {
          ...sourceItem,
          properties: sourceItem.properties.slice().map((sourceProperty) => {
            const property = { ...sourceProperty };
            delete property.editing;
            delete property._dateValue;
            delete property.subscribedUsers;
            return property;
          }),
        };
        delete item.isNew;
        delete item.editing;
        return item;
      }),
      properties: sourceListProperties.slice().map((sourceProperty) => {
        const property = { ...sourceProperty };
        delete property.editing;
        delete property._dateValue;
        return property;
      }),
    };
  }

  deleteProperty(property: ListProperty) {
    this.selectedList.items = this.selectedList.items.map((item) => {
      item.properties = item.properties.filter((entry) => entry.id !== property.id);
      return item;
    });
  }

  private visitListProperties(lists: List[], timezone: string) {
    return lists
      .map((list) => ({
        ...list,
        items: defaultTo(list.items, [] as typeof list.items).map((sourceItem) => {
          sourceItem.properties = this.visitDateProperties(sourceItem.properties, timezone);
          return sourceItem;
        }),
        properties: defaultTo(uniqBy(list.properties, 'id'), [] as typeof list.properties),
      }))
      .map((list) => ({
        ...list,
        properties: this.visitDateProperties(list.properties, timezone),
      }));
  }

  private visitDateProperties(properties: List['properties'], timezone: string) {
    const dateTypes = [ListEntryTypes.Date, ListEntryTypes.DateExpiry];
    return properties.map((property) => {
      const clonedProperty = { ...property };
      if (dateTypes.includes(property.kind) && property.value) {
        const date = tz(clonedProperty.value, timezone);
        clonedProperty._dateValue = {
          year: Number(date.format('YYYY')),
          month: Number(date.format('M')),
          day: Number(date.format('D')),
        };
      }
      return clonedProperty;
    });
  }

  private sortItems(items: List['items']) {
    const formattedItems: typeof items = defaultTo(items, []);
    return formattedItems.sort((previous, current) => {
      if (typeof previous.value === 'string') {
        return previous.value.localeCompare(String(current.value));
      }
      return 0;
    });
  }
}
