<div *ngIf="model.kind === fieldKinds.Category">
  <div
    class="form-group row {{ model.kind }}"
    [class.invalid-field]="model._invalid"
    [class.has-logic-rules]="hasLogicRules"
  >
    <div class="row col-12">
      <cc-form-field-label [model]="model" class="col-3 form-label" [label]="model.description" />
      <div class="col-9 list-input-container">
        <div class="form-control-static" *ngIf="!hasNoSelection; else noSelection">
          <div *ngIf="!model.readOnly">
            <div *ngIf="model.categoryType === categoryTypes.Dropdown">
              <ng-select
                [items]="categoryOptions"
                class="form-category-dropdown sm-rounded-none sm-border-0"
                ccScrollInputFocus=".form-modal"
                placeholder="Select or search"
                i18n-placeholder="Select or search"
                [(ngModel)]="selectedOption"
                name="{{ 'category-' + model.id }}"
                (change)="onDropdownSelect($event); onFieldChange()"
              >
                <ng-template ng-label-tmp let-item="item">
                  <div class="sm-flex">
                    <div class="category-dropdown selected-dropdown {{ item.style }}"></div>
                    <span>{{ item.value }}</span>
                  </div>
                </ng-template>
                <ng-template ng-option-tmp let-item="item">
                  <div class="sm-flex">
                    <div class="category-dropdown {{ item.style }}"></div>
                    <span>{{ item.value }}</span>
                  </div>
                </ng-template>
              </ng-select>
            </div>

            <div *ngIf="model.categoryType === categoryTypes.Inline" class="form-category-container">
              <span
                (click)="onToggleSelected(field); onFieldChange()"
                *ngFor="let field of categoryOptions"
                [ngClass]="getSelectedStyle(field)"
                class="category-item"
                data-test="inline-category-item"
              >
                {{ field.value }}
              </span>
            </div>
          </div>
          <div *ngIf="model.readOnly" class="form-category-container">
            <span
              *ngFor="let item of model.selectedItems"
              [ngClass]="item.style || styleVariants.None"
              class="category-item category-item-selected"
            >
              {{ item.value }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #noSelection>
  <div class="form-control-static" i18n>No selection</div>
</ng-template>
