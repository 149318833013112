<div class="sm-flex sm-w-full sm-items-center sm-justify-between">
  <h1 class="sm-text-xl sm-font-medium" i18n data-test="title">Reset Workflow</h1>
  <button type="button" class="close" aria-label="Close" (click)="directCloseModal()">
    <span aria-hidden="true"><i class="cc-icon cc-icon-close sm-text-default-text"></i></span>
  </button>
</div>
<h2 i18n class="px-2" data-test="subtitle">Select the column you wish to reset the workflow to.</h2>
<ng-select
  name="workflow-column"
  [(ngModel)]="selectedWorkflowColumn"
  [items]="columnSelectorValues"
  bindLabel="label"
  bindValue="_id"
  i18n-placeholder="Select workflow column..."
  placeholder="Select workflow column..."
  autofocus
  class="dropdown-selector"
>
  <ng-template ng-option-tmp let-item="item" let-search="searchTerm">
    <div class="sm-flex sm-items-center sm-justify-between">
      <div class="dropdown sm-text-default-text" [innerHTML]="item.label" [disabled]="item.disabled"></div>
      <i
        *ngIf="item.disabled"
        class="cc-icon cc-icon-question permission-option-icon"
        [ngbPopover]="permissionPopoverContent"
        triggers="hover"
        placement="right"
        container="body"
      ></i>
    </div>
  </ng-template>
</ng-select>
<div class="action-buttons">
  <button
    type="button"
    data-test="reset-workflow-button"
    class="button-regular button-red"
    (click)="resetWorkflow()"
    [disabled]="!selectedWorkflowColumn"
  >
    {{ submitButtonLabel }}
  </button>
  <button type="button" class="button-regular button-grey-3" (click)="directCloseModal()">Cancel</button>
</div>

<ng-template #permissionPopoverContent>
  <p class="!sm-text-default-text" i18n>
    You do not have the correct user type or permission to reset this workflow to this column.
  </p>
</ng-template>
