import { FieldKinds, TableCellData, TableCellKinds } from '@site-mate/dashpivot-shared-library';

import { FieldWeb } from 'app/shared/model/item.model';

import { FormItemBaseChangeDetector } from './form-item-change-detector-base';

export class TableChangeDetector extends FormItemBaseChangeDetector {
  hasChanged(newItem: FieldWeb, oldItem: FieldWeb): boolean {
    if (this.isPotentiallyANewTable(oldItem, newItem)) {
      return !this.hasOnlyEmptyValues(newItem);
    }

    if (this.haveDifferentNumberOfRows(oldItem, newItem)) {
      return true;
    }

    if (this.areBothTablesEmpty(oldItem, newItem)) {
      return false;
    }

    return !this.areAllCellsEqual(oldItem, newItem);
  }

  private isPotentiallyANewTable(oldTable: FieldWeb, newTable: FieldWeb) {
    const { oldRowCount, newRowCount } = this.getRowsCount(oldTable, newTable);

    return (
      oldTable.kind === FieldKinds.Table && oldRowCount === 0 && newRowCount === 1 && newTable.rows[0].columns
    );
  }

  private hasOnlyEmptyValues(newTable: FieldWeb) {
    // we add a blank row to default tables whenever a form is opened, check if it has a value
    const hasOnlyEmptyValues = !newTable.rows[0].columns
      // strip out formula columns
      .filter((_cell, index) => newTable.columns[index].kind !== TableCellKinds.Formula)
      // check if any other cells have values
      .some((cell) => !!cell.value || !!cell.photos || !!cell.signatures || !!cell.attachments);

    return hasOnlyEmptyValues;
  }

  private haveDifferentNumberOfRows(oldTable: FieldWeb, newTable: FieldWeb) {
    const { oldRowCount, newRowCount } = this.getRowsCount(oldTable, newTable);

    return oldRowCount !== newRowCount;
  }

  private areBothTablesEmpty(oldTable: FieldWeb, newTable: FieldWeb) {
    const { oldRowCount, newRowCount } = this.getRowsCount(oldTable, newTable);

    return oldRowCount !== newRowCount;
  }

  private getRowsCount(oldTable: FieldWeb, newTable: FieldWeb) {
    const oldRowCount = oldTable.rows?.length || 0;
    const newRowCount = newTable.rows?.length || 0;

    return { oldRowCount, newRowCount };
  }

  private areAllCellsEqual(oldTable: FieldWeb, newTable: FieldWeb) {
    return newTable.rows.every((newRow, rowIndex) => {
      return newRow.columns.every((newCell, columnIndex) => {
        const oldCell = oldTable.rows[rowIndex].columns[columnIndex];
        return this.areTableCellsEqual(newCell, oldCell);
      });
    });
  }

  private areTableCellsEqual(tableCellA: TableCellData, tableCellB: TableCellData) {
    if (tableCellA.kind !== tableCellB.kind) {
      return false;
    }

    const tableCellComparers = {
      [TableCellKinds.Formula]: () => true,
      default: () => {
        return JSON.stringify(tableCellA) === JSON.stringify(tableCellB);
      },
    };

    const comparer = tableCellComparers[tableCellA.kind] || tableCellComparers.default;

    return comparer();
  }
}
