/* eslint-disable max-lines */
import { Location, PlatformLocation } from '@angular/common';
import {
  ChangeDetectorRef,
  Directive,
  ElementRef,
  inject,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { cloneDeep, kebabCase, omit } from 'lodash-es';
import { BehaviorSubject, Subject, lastValueFrom, of } from 'rxjs';
import { catchError, debounceTime, filter, takeUntil, map } from 'rxjs/operators';

import {
  Comment,
  Company,
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  Field,
  FieldKinds,
  FormulasVersions,
  FormAttachment,
  HeaderFooterDefaultFields,
  HeaderFooterField,
  HeaderFooterFieldKinds,
  List,
  Template,
  TemplateOrientations,
  ListItem,
  Modules,
} from '@site-mate/dashpivot-shared-library';

import { TemplatesService } from 'app/apps/templates.service';
import { FormManualSignatureComponent } from 'app/form/form-components/form-manual-signature.component';
import { FormService } from 'app/form/form.service';
import { ListPropertyFormService } from 'app/form-fields/table/edit-cells/list-property-form.service';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { ListsService } from 'app/lists/lists.service';
import { ITask } from 'app/sam/model/task.model';
import { SamService } from 'app/sam/sam.service';
import { SegmentService } from 'app/segment/segment.service';
import { FormWeb } from 'app/shared/model/form.model';
import { FieldWeb } from 'app/shared/model/item.model';
import { ListsHandler } from 'app/shared/model/lists/lists-handler.model';
import { IdNormalizer } from 'app/shared/model/utils/id-normalizer.model';
import { AccountService } from 'app/shared/service/account.service';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ConfirmService, confirmExitOperations } from 'app/shared/service/confirm.service';
import { ErrorHandler } from 'app/shared/service/error-handler.service';
import { FormValidationService } from 'app/shared/service/form-validation.service';
import { FormulaEngineService } from 'app/shared/service/formula-engine.service';
import { HotFormulaParserEngineService } from 'app/shared/service/hot-formula-parser-engine.service';
import { HyperFormulaEngineService } from 'app/shared/service/hyper-formula-engine.service';
import { LogicRuleService } from 'app/shared/service/logic-rules.service';
import { MetaDataService } from 'app/shared/service/meta-data.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { ProfileService } from 'app/user/profile.service';
import { UserService } from 'app/user/user.service';
import { environment } from 'environments/environment';

import { FormSignUpToDownloadModalComponent } from './form-sign-up-to-download-modal/form-sign-up-to-download-modal.component';
import { ResetWorkflowComponent } from './reset-workflow/reset-workflow.component';
import { FormModalAction } from '../../apps/template-form-edit/form-modal-action.model';
import { FormEventsService } from '../form-events.service';
import { FormItemsChangeDetector } from '../form-item-change-detectors/form-items-change-detector';
import { FormUtil } from '../form-util.service';

interface ErrorWithToastKey extends Error {
  toastKey?: string;
}

@Directive()
// tslint:disable-next-line: directive-class-suffix
export abstract class AddEditFormBaseComponent implements OnDestroy, OnInit {
  private readonly listPropertyFormService = inject(ListPropertyFormService);
  private readonly accountService = inject(AccountService);
  private readonly uploadBatchSize = 2;
  private readonly fieldDebounceTime = 500;

  readonly metadataList$ = new BehaviorSubject<List[]>(null);
  onDestroySub = new Subject<void>();

  colorPickerState$ = new Subject<boolean>();
  originalItems: FieldWeb[];

  @ViewChild('submitButton') submitButton: ElementRef;
  @Input() scrollTop = 0;
  @Input() templateName: string;
  @Input({ required: true }) templateId: string; // Passed when we're opening in create or preview
  @Input() formId: string; // Only passed when editing an existing form
  @Input() isLibraryView: boolean;
  @Input() isPreview: boolean;
  @Input() isTemplateEditorPreview = false;

  isWorking: boolean;
  isCloning: boolean;
  canSave: boolean;
  hasFormBeenSaved: boolean = false;
  editForm: boolean;
  showActions: boolean;
  showActivities: boolean;
  saving: boolean;
  form: FormWeb;
  template: Template;
  attachmentUploading: { id: number; name: string }[] = [];

  navigateOnSignUpToFormModal: boolean;

  readonly templateOrientations = TemplateOrientations;
  readonly headerFooterKind = HeaderFooterFieldKinds;

  teamName: string;
  parentId: string;
  projectName: string;
  company: Company;
  errorToastLife = 5 * 60 * 1000;
  renderedFields: Field[];
  activities: Comment[] = [];
  filePath = '';
  isHeaderSettingsLoaded = false;
  headerFields: HeaderFooterField[] = [];
  formulaEngineService: FormulaEngineService;
  activeSignUpModal: boolean;

  constructor(
    protected readonly location: Location,
    protected readonly platformLocation: PlatformLocation,
    protected readonly activeModal: NgbActiveModal,
    protected readonly i18nService: TmpI18NService,
    protected readonly listsService: ListsService,
    protected readonly errorHandler: ErrorHandler,
    protected readonly toastr: ToastrService,
    protected readonly confirmService: ConfirmService,
    protected readonly formService: FormService,
    protected readonly modal: NgbModal,
    protected readonly metaDataService: MetaDataService,
    protected readonly profileService: ProfileService,
    protected readonly segmentService: SegmentService,
    protected readonly userService: UserService,
    protected readonly formValidationService: FormValidationService,
    protected readonly logicRuleService: LogicRuleService,
    protected readonly hostElement: ElementRef,
    protected readonly changeDetectorRef: ChangeDetectorRef,
    protected readonly templatesService: TemplatesService,
    protected readonly injector: Injector,
    protected readonly formEventsService: FormEventsService,
    protected readonly appUtilService: AppUtilService,
    protected readonly samService: SamService,
  ) {}

  ngOnInit() {
    this.formulaEngineService = this.getFormulasServiceEngine();
    this.formService.onFieldChanged
      .pipe(takeUntil(this.onDestroySub), debounceTime(this.fieldDebounceTime))
      .subscribe(() => {
        this.canSave = true;
        if (this.isLibraryView && !this.activeSignUpModal) {
          this.activeSignUpModal = true;
          this.waitAndOpenSignupToDownloadModal();
        }
      });
  }

  ngOnDestroy() {}

  // NOTE: This method is similar to ngOnDestroy, but needs to be called explicitly before the modal is closed
  // DPW-7514 - Discovered that a modal re-opening, will initialise before the previous is destroyed & our service data is shared
  cleanUpBeforeClose() {
    this.formulaEngineService.destroy();
    this.listPropertyFormService.clearListPropertyCellMap();
  }

  get isFormReady() {
    return this.isHeaderSettingsLoaded && this.form;
  }

  abstract onClose();

  abstract beforeDismiss();

  remove() {
    throw new Error('Not implemented');
  }

  download() {
    throw new Error('Not implemented');
  }

  onSend() {
    throw new Error('Not implemented');
  }

  clone() {
    throw new Error('Not implemented');
  }

  onUseThisTemplate() {
    throw new Error('Not implemented');
  }

  trackFormFields(_index, item) {
    return `${item.id}-${Boolean(item.readOnly)}`;
  }

  decorateItems() {
    FormUtil.decorateTableColumnItems(this.form.items);
  }

  updateOriginalFormItems() {
    this.originalItems = cloneDeep(FormUtil.undecorateItems(this.form.items));
  }

  setupContext(template) {
    const { team, project, company } = template;
    this.teamName = team?.name;
    this.parentId = project && new IdNormalizer(project).value();
    this.projectName = project?.name;
    this.company = company;
    this.filePath = `${this.company?.name || '-'}/${this.projectName || '-'}/${this.teamName || '-'}`;
  }

  setupForm(form) {
    // Setting the template before the form to avoid potential race condition with multiple form.items associations
    this.template = form.template;
    this.templateName = this.template.name;
    this.form = form;
    this.form.items = this.logicRuleService.evaluateLogicRules(this.form.items);
    this.decorateItems();
    this.updateOriginalFormItems();
    this.form.items = this.setupLogicRuleFields(this.form.items);
    this.setupContext(form.template);
    this.setupSamEnabledFields();
    this.setAutomatedFormNumber();
    this.setupMetadataList();
    this.initFormulaEngine();
  }

  setupSamEnabledFields() {
    const samEnabled =
      this.company.featureAccess?.sam?.enabled && this.company.featureAccess?.sam?.features?.docketImports;
    if (samEnabled) {
      const templateId = this.template.organisationTemplateId || this.template._id;
      this.samService
        .listTasks(templateId)
        .pipe(
          takeUntil(this.onDestroySub),
          filter((tasks) => tasks && tasks.length > 0),
        )
        .subscribe(this.assignSamTasks.bind(this));
    }
  }

  private assignSamTasks(tasks: ITask[]) {
    tasks.forEach((task) => {
      const itemIndex = this.form.items.findIndex((item) => item._id === task.details.itemId);
      if (itemIndex !== -1) {
        const newItem = {
          ...this.form.items[itemIndex],
          samTask: task,
        };
        // This ensures change detection is only triggered for the updated item
        this.form.items.splice(itemIndex, 1, newItem);
      }
    });
  }

  resetFormFields(fieldsToReset: Field[]) {
    const resetFields = this.logicRuleService.resetFields({
      fieldsToReset,
      templateFields: this.template.items,
      formItems: this.form.items,
      formulaEngineService: this.formulaEngineService,
    });

    this.form.items = this.setupLogicRuleFields(resetFields);
    this.formService.onFieldChanged.next();
  }

  private setupLogicRuleFields(formFields: FieldWeb[]): FieldWeb[] {
    return formFields.map((field) => {
      if (this.logicRuleService.hasLogicRules(field)) {
        field.logicRules.forEach((rule) => {
          rule.fields = formFields
            .filter((formField) => this.logicRuleService.dependsOnAnotherField(formField))
            .filter((formField) => this.logicRuleService.dependsOnRule(formField, rule));
        });
      }

      return field;
    });
  }

  setupMetadataList() {
    const listsIds = this.formService.getFormListDependencies(this.form);

    if (!listsIds.length) {
      return;
    }

    this.listsService.getListsByIds(listsIds).subscribe({
      next: (lists) => {
        const filteredLists = this.filterDeployedListItems(lists);
        this.metadataList$.next(filteredLists);
      },
      error: (error) => {
        this.errorHandler.handle(error);
      },
    });
  }

  hasFormBeenChanged(): boolean {
    const itemsCleaned = FormUtil.undecorateItems(this.form.items);
    return FormItemsChangeDetector.hasAnyItemChanged(this.originalItems, itemsCleaned);
  }

  private filterDeployedListItems(lists: List[]) {
    return lists.map((list) => {
      const localList = ListsHandler.service.filterDeployedListItems({ ...list }, this.parentId);
      localList.items = localList.items?.sort(this.sortListItems.bind(this));
      return localList;
    });
  }

  private sortListItems(previousItem: ListItem, nextItem: ListItem) {
    return previousItem.value && String.prototype.localeCompare
      ? String(previousItem.value).localeCompare(String(nextItem.value))
      : 0;
  }

  initializeHeaderSettings(templateId: string) {
    this.templatesService
      .byId(templateId)
      .pipe(takeUntil(this.onDestroySub))
      .subscribe(
        (template: Template) => {
          this.setHeaderSettings(template);
        },
        (error) => {
          this.errorHandler.handle(error);
          this.isHeaderSettingsLoaded = true;
        },
      );
  }

  setHeaderSettings(template: Template) {
    this.headerFields = template.headerItems
      ? template.headerItems.filter((field) => field.isVisible !== false)
      : HeaderFooterDefaultFields.header;
    this.isHeaderSettingsLoaded = true;
  }

  isValidForSigning(itemId: string) {
    return (
      this.formValidationService.isValidForSigning(this.form, itemId) &&
      this.formValidationService.isValid(this.form)
    );
  }

  isValid(ignoreRequiredValidation?: boolean) {
    return this.formValidationService.isValid(this.form, ignoreRequiredValidation);
  }

  onToggleActivities() {
    this.showActivities = !this.showActivities;
  }

  async onSign(itemId: string) {
    if (!this.isValidForSigning(itemId)) {
      this.toastr.error(this.i18nService.getMessage('invalidForm'));
      return;
    }

    const itemsCleaned = FormUtil.undecorateItems(this.form.items);

    try {
      if (!this.form._id || this.hasFormBeenChanged()) {
        await this.confirmService.confirm('saveBeforeSign', {
          confirmButtonText: this.i18nService.getMessage('saveAndSign'),
        });

        if (!this.form._id) {
          await this.createFormInDb({ ...this.form, items: itemsCleaned });
        } else {
          const updatedForm = await lastValueFrom(this.formService.updateItems(this.form._id, itemsCleaned));
          this.form.formVersion = updatedForm.formVersion;
        }
        this.changeDetectorRef.detectChanges();
      } else {
        await this.confirmService.confirmSign('signForm');
      }

      await lastValueFrom(this.formService.sign(this.form._id, itemId));
      this.cleanUpBeforeClose();
      this.activeModal.close({
        reason: FormModalAction.Sign,
        formId: this.form._id,
        scrollTop: this.hostElement.nativeElement.scrollTop,
      });
      this.toastr.successByKey('formSaved');
    } catch (error) {
      if (!confirmExitOperations.includes(error as string)) {
        this.errorHandler.handle(error);
      }
    }
  }

  async onResetSignature() {
    const itemsCleaned = FormUtil.undecorateItems(this.form.items);
    const formChanged = this.hasFormBeenChanged();
    try {
      const modalRef = this.modal.open(ResetWorkflowComponent, {
        windowClass: 'modal-500 modal-overlay',
        modalDialogClass: 'modal-dialog-centered',
      });
      modalRef.componentInstance.form = this.form;
      modalRef.componentInstance.planType = this.accountService.getAccountPlanType();
      modalRef.componentInstance.submitButtonLabel = formChanged ? 'Save and Reset' : 'Save';

      const selectedColumnId = await modalRef.result;

      if (formChanged) {
        this.saving = true;
        const { formVersion } = await lastValueFrom(this.formService.updateItems(this.form.id, itemsCleaned));
        this.form.formVersion = formVersion;
        this.saving = false;
      }

      await this.resetSignature(selectedColumnId);
    } catch (error) {
      if (error && !confirmExitOperations.includes(error as string)) {
        this.errorHandler.handle(error);
      }
    }
  }

  isFormValidated(): boolean {
    if (!this.form || !this.isValid(this.navigateOnSignUpToFormModal)) {
      this.toastr.error(this.i18nService.getMessage('invalidForm'));
      return false;
    }
    return true;
  }

  async saveForm(showToastr: boolean = true, skipValidation: boolean = false): Promise<boolean> {
    if (!skipValidation && !this.isFormValidated()) {
      return false;
    }
    this.saving = true;
    const formKind = this.form.kind;
    try {
      const itemsCleaned = FormUtil.undecorateItems(this.form.items);
      const isNewForm = !this.form._id;
      if (isNewForm) {
        const newForm = await this.createFormInDb({ ...this.form, items: itemsCleaned });
        this.addFormIdToUrl(newForm._id);
        this.originalItems = this.appUtilService.clone(newForm.items);
      } else {
        const { formVersion } = await lastValueFrom(
          this.formService.updateItems(this.form._id, itemsCleaned),
        );
        this.form = { ...this.form, formVersion };
        this.originalItems = this.appUtilService.clone(itemsCleaned);
      }

      if (showToastr) {
        if (formKind === Modules.Form) {
          this.toastr.successByKey(isNewForm ? 'formCreated' : 'formUpdated');
        }
        if (formKind === Modules.Action) {
          this.toastr.successByKey(isNewForm ? 'actionSaveSucceeded' : 'actionUpdateSucceeded');
        }
      }
      this.updateSavedStatus();
    } catch (error: unknown) {
      const errorWithToastKey = error as ErrorWithToastKey;
      errorWithToastKey.toastKey = 'formOrActionSaveFailed';
      this.errorHandler.handle(errorWithToastKey);
      return false;
    } finally {
      this.saving = false;
    }
    return true;
  }

  async resetSignature(selectedColumnId: string) {
    try {
      this.saving = true;
      await lastValueFrom(this.formService.resetSignature(this.form._id, selectedColumnId));
      this.cleanUpBeforeClose();
      this.activeModal.close({
        reason: FormModalAction.ResetSignature,
        formId: this.form._id,
        scrollTop: this.hostElement.nativeElement.scrollTop,
      });
      this.formService.onFieldChanged.next();
      this.saving = false;
    } catch (error) {
      this.saving = false;
      this.errorHandler.handle(error);
    }
  }

  onDeleteAttachment({
    attachment,
    attachments,
  }: {
    attachment: FormAttachment;
    attachments: FormAttachment[];
  }) {
    this.confirmService
      .confirmDelete('deleteAttachment')
      .then(() => {
        this.form.dynamicAttachments = attachments.filter((attachToRemove) => {
          const removableAttachmentId = new IdNormalizer(attachToRemove).value();
          const currentAttachmentId = new IdNormalizer(attachment).value();
          return removableAttachmentId !== currentAttachmentId;
        });
        return this.updateFormAttachments();
      })
      .catch(() => {});
  }

  uploadAttachmentGuard = async () => {
    if (this.form._id) {
      return true;
    }

    try {
      const confirmed = await this.confirmService.confirm('saveBeforeAttach', {
        confirmButtonText: this.i18nService.getMessage('confirmSaveAndAttach'),
        cancelButtonText: this.i18nService.getMessage('confirmCancel'),
      });
      if (!confirmed) {
        return false;
      }
      return await this.saveForm(true, true);
    } catch (err: unknown) {
      return false;
    }
  };

  async handleUploadAttachment(event) {
    this.saving = true;

    await this.formService.handleAttachmentUpload({
      uploadBatchSize: this.uploadBatchSize,
      target: event.target,
      uploader: (fakeId, formData) => {
        return this.formService.uploadFile(formData).pipe(
          map((resultAttachment) => {
            this.form.dynamicAttachments = this.form.dynamicAttachments || [];
            this.form.dynamicAttachments.push(resultAttachment);
            this.attachmentUploading = this.attachmentUploading.filter((u) => u.id !== fakeId);
            void EventNotifierService.notify(
              new DashpivotEvent(EventTypes.FormAttachmentAdded, { Context: 'PDF' }),
              this.segmentService,
            );

            return resultAttachment;
          }),
          catchError((error) => {
            this.saving = false;
            const insideIFrame = this.isLibraryView;
            if (!insideIFrame) {
              this.errorHandler.handle(error, null, null, {
                enableHTML: true,
                toastLife: this.errorToastLife,
              });
            }
            this.attachmentUploading = this.attachmentUploading.filter((u) => u.id !== fakeId);
            void EventNotifierService.notify(
              new DashpivotEvent(EventTypes.FormAttachmentFailed, { Context: 'PDF' }),
              this.segmentService,
            );
            return of(null);
          }),
        );
      },
    });

    this.updateFormAttachments();
  }

  private updateFormAttachments() {
    this.formService
      .updateItems(this.form._id, null, { dynamicAttachments: this.form.dynamicAttachments })
      .pipe(takeUntil(this.onDestroySub))
      .subscribe({
        next: ({ formVersion }) => {
          this.form.formVersion = formVersion;
          this.saving = false;
        },
        error: (error) => {
          this.saving = false;
          const insideIFrame = this.isLibraryView;
          if (!insideIFrame) {
            this.errorHandler.handle(error);
          }
        },
      });
  }

  pushSignature(model, signature) {
    const userId = this.userService.getCurrentUser().id;
    if (!model.signatures) {
      model.signatures = [];
    }

    const newSignature = {
      fullName: signature.fullName,
      signatureUrl: signature.signatureUrl,
      companyName: signature.companyName,
      signedOn: signature.signedOn,
      userId,
    };

    model.signatures = [...model.signatures, newSignature];
    this.changeDetectorRef.detectChanges();
  }

  updateSavedStatus() {
    this.hasFormBeenSaved = true;
    this.canSave = false;
  }

  addFormIdToUrl(formId: string) {
    let currentPath = this.location.path();

    const queryStringIndex = currentPath.indexOf('?');
    let queryString = '';

    if (queryStringIndex !== -1) {
      queryString = currentPath.substring(queryStringIndex);
      currentPath = currentPath.substring(0, queryStringIndex);
    }

    const segments = currentPath.split('/');
    segments[segments.length - 1] = formId;

    const newPath = segments.join('/') + queryString;

    this.location.replaceState(newPath);
  }

  async createFormInDb(form: FormWeb): Promise<FormWeb> {
    const formCreate = omit(form, ['createdBy', 'template']);
    const formSaved = await lastValueFrom(this.formService.create(this.template._id, formCreate));
    this.formId = formSaved._id;
    this.form = formSaved;
    return formSaved;
  }

  getFieldById(fieldId: string): FieldWeb {
    return this.form.items.find((item) => (item._id || item.id) === fieldId);
  }

  async handleApprovalSign({ id }: FieldWeb) {
    if (!this.profileService.hasProfileSignature()) {
      return;
    }

    await this.onSign(id);
  }

  async handleNormalSign(model: FieldWeb) {
    if (!this.profileService.hasProfileSignature()) {
      return;
    }

    try {
      if (!this.form._id) {
        await this.confirmService.confirm('saveBeforeSign', {
          confirmButtonText: this.i18nService.getMessage('saveAndSign'),
          cancelButtonText: this.i18nService.getMessage('confirmCancel'),
        });

        const saved = await this.saveForm(true, true);
        if (saved) {
          await this.normalSign(model);
        }
      } else {
        await this.confirmService.confirmSign('signForm');
        await this.normalSign(model);
      }
    } catch (error) {
      // no-op: confirmation cancelled
    }
  }

  normalSign(model: FieldWeb) {
    return this.formService
      .sign(this.form._id, model.id || model._id)
      .pipe(takeUntil(this.onDestroySub))
      .subscribe({
        next: (res) => {
          const field = this.getFieldById(model.id || model._id);
          if (!field) {
            this.toastr.error(this.i18nService.getMessage('generalErr'));
            this.errorHandler.handle(new Error('Signature field not found'));
            return;
          }
          this.pushSignature(field, res);
          this.formService.onFieldChanged.next();
          model._invalid = false;
        },
        error: (error) => {
          this.errorHandler.handle(error);
        },
      });
  }

  async handleManualSign(model: FieldWeb) {
    try {
      if (!this.form._id) {
        await this.confirmService.confirm('saveBeforeSign', {
          confirmButtonText: this.i18nService.getMessage('saveAndSign'),
          cancelButtonText: this.i18nService.getMessage('confirmCancel'),
        });

        const saved = await this.saveForm(true, true);
        if (saved) {
          this.changeDetectorRef.detectChanges();
          await this.openManualSignModal(model);
        }
      } else {
        await this.openManualSignModal(model);
      }
    } catch (error) {
      // no-op: confirmation cancelled
    }
  }

  private async openManualSignModal(model: FieldWeb) {
    const modalRef = this.modal.open(FormManualSignatureComponent);

    await modalRef.result
      .then((signature) => {
        return this.formService.manualSign(this.form._id, model.id, signature).subscribe({
          next: (res) => {
            const field = this.getFieldById(model.id);
            if (!field) {
              this.toastr.error(this.i18nService.getMessage('generalErr'));
              this.errorHandler.handle(new Error('Signature field not found'));
              return;
            }
            this.pushSignature(field, res);
            this.formService.onFieldChanged.next();
            model._invalid = false;
          },
          error: (error) => this.errorHandler.handle(error),
        });
      })
      .catch(() => {
        // no-op: modal dismissed
      });
  }

  private setAutomatedFormNumber() {
    if (this.form.automatedFormNumber || this.form.isPreview) {
      return;
    }

    const defaultPlaceholder = '[Folder path]';
    const prefix = this.template.isOrganisationTemplate
      ? [defaultPlaceholder]
      : [this.company.name, this.projectName, this.teamName];

    const counterComponents = [...prefix, this.template.uniqueAppId, this.form._id ? this.form.counter : '#'];

    this.form.automatedFormNumber = counterComponents.every((comp) => !!comp)
      ? counterComponents.join('-')
      : '';
  }

  initFormulaEngine() {
    this.formulaEngineService = this.getFormulasServiceEngine();
    this.formulaEngineService.init();
    this.initSheets();
    this.formulaEngineService.setValuesUpdateListener((changes) => {
      this.formEventsService.emitFormulaFieldChangedEvent(changes);
    });
  }

  private initSheets() {
    this.form.items.filter(this.isTableField).forEach(({ tableReference }) => {
      this.formulaEngineService.addSheet(tableReference);
      this.formulaEngineService.setEmptySheetContent(tableReference);
    });
  }

  private isTableField(field: Field): boolean {
    return [FieldKinds.Table, FieldKinds.PrefilledTable].includes(field.kind);
  }

  private get isTemplateFormulasVersion2(): boolean {
    return (this.template?.formulasVersion as FormulasVersions) === FormulasVersions.V2;
  }

  private getFormulasServiceEngine(): FormulaEngineService {
    return this.isTemplateFormulasVersion2
      ? this.injector.get(HyperFormulaEngineService)
      : this.injector.get<FormulaEngineService>(HotFormulaParserEngineService);
  }

  waitAndOpenSignupToDownloadModal() {
    const fiveSecondsInMilliSeconds = 5000;
    setTimeout(() => {
      const signupToDownloadModal = this.modal.open(FormSignUpToDownloadModalComponent, {
        windowClass: 'modal-500 modal-overlay',
      });
      signupToDownloadModal.componentInstance.signupUrl = this.getSignUpUrl();
      signupToDownloadModal.componentInstance.title = this.i18nService.getMessage('signUpToSave');
      signupToDownloadModal.componentInstance.buttonLabel =
        this.i18nService.getMessage('signUpSaveButtonLabel');

      signupToDownloadModal.dismissed.subscribe(() => {
        this.activeSignUpModal = false;
      });
    }, fiveSecondsInMilliSeconds);
  }

  getSignUpUrl(): string {
    const templateNameInKebabCase = kebabCase(this.template.name);
    return `${environment.uiUrl}/signup?reference=${templateNameInKebabCase}-template-iframe&templateId=${this.template._id}`;
  }
}
