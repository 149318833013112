import { Injectable } from '@angular/core';

import { Event, EventNotifierService } from '@site-mate/dashpivot-shared-library';

import { SegmentService } from 'app/segment/segment.service';
import { TimingMarker } from 'app/shared/model/timing-marker.model';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class TimingMarkerService {
  constructor(private readonly segmentService: SegmentService) {}

  public start(event: Event): TimingMarker {
    const timingMarker = new TimingMarker(event);
    if (environment.segment.logActionsOnConsole) {
      const eventMessage = [timingMarker.event.eventType, '- started at', timingMarker.getStartTime()].join(
        ' ',
      );
      // eslint-disable-next-line no-console
      console.log(eventMessage);
    }
    return timingMarker;
  }

  public finish(timingMarker?: TimingMarker, success = true) {
    if (!timingMarker) {
      return;
    }
    const ellapsedTime = timingMarker.getElapsedTime();
    const event = new Event(timingMarker.event.eventType, {
      ...timingMarker.event.metadata,
      ellapsedTime,
      success,
    });
    void EventNotifierService.notify(event, this.segmentService);
    if (environment.segment.logActionsOnConsole) {
      const eventMessage = [
        timingMarker.event.eventType,
        `- finished ${success ? 'successfully' : 'with failure'}. Time taken: `,
        ellapsedTime,
        'ms',
      ].join(' ');
      // eslint-disable-next-line no-console
      console.log(eventMessage);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public finishWithFailure(timingMarker?: TimingMarker, error?: any) {
    this.finish(timingMarker, false);
  }
}
