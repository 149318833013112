<label
  class="default-label btn btn-primary"
  (click)="uploadGuard($event)"
  tabindex="0"
  (keydown)="handleKeyboardEvent($event)"
>
  <span class="sm-flex sm-items-center">
    <span i18n>Add PDF</span>
    <span class="cc-icon cc-icon-attachment sm-pl-1"></span>
  </span>
  <input
    type="file"
    multiple
    name="files"
    class="d-none"
    (change)="upload($event)"
    accept="application/pdf"
    #fileInput
  />
</label>
<div class="d-inline-block" *ngIf="attachmentUploading.length > 0">
  <span class="loading-spinner">
    <i class="cc-icon cc-icon-spinner-fw"></i>
  </span>
  <span i18n>Uploading</span>
  <span *ngFor="let u of attachmentUploading" class="ml-2">{{ u.name }}</span>
</div>

<div *ngFor="let attachment of attachments">
  <hr class="hr" />
  <div class="my-2 attachment-buttons-row sm-justify-between">
    <div>
      <a
        [href]="attachment.urlDownload"
        target="_blank"
        rel="noopener"
        class="my-1 text-main sm-flex sm-items-center sm-space-x-1"
      >
        <i
          class="cc-icon"
          [class.cc-icon-file-pdf]="attachment.mediaType === pdfMimeType"
          [class.cc-icon-text]="attachment.mediaType !== pdfMimeType"
          aria-hidden="true"
        ></i
        >{{ attachment.filename }}
      </a>
    </div>
    <div class="attachment-content-row">
      <a
        (click)="onDeleteAttachment(attachment)"
        class="sm-items-centern sm-my-1 sm-flex sm-space-x-1 sm-text-default-red"
      >
        <i class="cc-icon cc-icon-delete sm-flex sm-items-center" aria-hidden="true"></i
        ><span i18n>Delete</span></a
      >
    </div>
  </div>
  <div class="attachment-content-row" *ngIf="attachment.mediaType === pdfMimeType">
    <cc-form-pdf-preview [attachment]="attachment"></cc-form-pdf-preview>
  </div>
  <div class="row" *ngIf="attachment.mediaType !== pdfMimeType">
    <div class="col">
      <img class="img-preview" [ccFallbackSrc]="attachment.urlDownload" alt="attachment.filename" />
    </div>
  </div>
</div>
