import { Component, OnInit } from '@angular/core';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  FormFieldValidatorFactory,
} from '@site-mate/dashpivot-shared-library';

import { FormBaseComponent } from 'app/form/form-components/form-base.component';
import { FormService } from 'app/form/form.service';
import { SegmentService } from 'app/segment/segment.service';
import { FieldWeb } from 'app/shared/model/item.model';
import { AppUtilService } from 'app/shared/service/app-util.service';

@Component({
  selector: 'cc-form-single-input',
  templateUrl: 'form-single-input.component.html',
  styleUrls: ['../../../form/form-components/form-component.scss'],
})
export class FormSingleInputComponent extends FormBaseComponent<FieldWeb> implements OnInit {
  constructor(
    protected readonly appUtilService: AppUtilService,
    protected readonly formService: FormService,
    private readonly segmentService: SegmentService,
  ) {
    super(appUtilService, formService);
  }

  ngOnInit() {
    this.validator = FormFieldValidatorFactory.getValidator(this.model);
  }

  onChange() {
    this.model._dirty = true;
    this.model._invalid = !this.validator.isValid(this.model);
  }

  public onBlur() {
    super.onBlur();
    this.editing = false;
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.FormSingleInputTextTyped),
      this.segmentService,
    );
  }
}
