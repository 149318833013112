<div class="form-group row">
  <cc-form-field-label class="col-3 prefilled-text-label" [model]="model" [label]="model.description" />
  <div class="col-12 form-control-static prefilled-text-container">
    <div
      class="editor-content multi-line-text-preview prefilled-text"
      [innerHTML]="model.content | safeHtml"
      data-test="prefilled-text"
    ></div>
  </div>
</div>
