<div>
  <div class="canvas-toolbox">
    <div class="form-inline">
      <button
        [ngClass]="{ 'btn-secondary': !isFreeDraw, 'btn-info': isFreeDraw }"
        class="btn"
        type="button"
        (click)="toggleFreeDraw()"
      >
        <i class="fa fa-fw fa-paint-brush"></i>
        <span i18n *ngIf="!isFreeDraw">Enable free draw</span>
        <span i18n *ngIf="isFreeDraw">Disable free draw</span>
      </button>
      <div ngbDropdown class="ml-1 position-relative">
        <button type="button" class="btn btn-secondary" ngbDropdownToggle>
          <i class="fa fa-fw fa-cube"></i>
          <span i18n>Add shape</span>
        </button>
        <div class="dropdown-menu" ngbDropdownMenu>
          <a class="dropdown-item" (click)="addSquare()">
            <span i18n>Square</span>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="addRect()">
            <span i18n>Rectangle</span>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="addCircle()">
            <span i18n>Circle</span>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="addTriangle()">
            <span i18n>Triangle</span>
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="addLine()">
            <span i18n>Line</span>
          </a>
        </div>
      </div>

      <div class="ml-1 input-group">
        <input
          type="text"
          class="form-control border-right-0"
          placeholder="Input text"
          i18n-placehoder="Input text"
          #textInput
          (keyup.enter)="addText(textInput)"
        />
        <div class="input-group-append">
          <button class="btn btn-secondary add-text-button" type="button" i18n (click)="addText(textInput)">
            Add text
          </button>
        </div>
      </div>

      <button class="ml-1 btn btn-secondary sm-text-default-red" type="button" (click)="deleteSelected()">
        <i class="fa fa-trash-o"></i> <span i18n>Delete</span>
      </button>
    </div>
  </div>
  <div class="canvas-wrapper">
    <div
      class="canvas-bg"
      [style.background-image]="getBackgroundUrl()"
      [style.background-repeat]="model.background ? 'no-repeat' : 'repeat'"
      [style.background-size]="model.background ? 'contain' : 'auto'"
    >
      <canvas [id]="id" class="canvas sm-inline-block" width="730" height="500"></canvas>
    </div>
  </div>
</div>
