import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { environment } from 'environments/environment';

@Component({
  selector: 'cc-form-sign-up-to-download-modal',
  templateUrl: './form-sign-up-to-download-modal.component.html',
  styleUrls: ['./form-sign-up-to-download-modal.component.scss'],
})
export class FormSignUpToDownloadModalComponent {
  title: string;
  signupUrl: string;
  buttonLabel: string;
  baseUrl = environment.uiUrl;

  constructor(public readonly activeModal: NgbActiveModal) {}

  closeModal() {
    this.activeModal.dismiss();
  }
}
