<div class="modal-header">
  <h4 class="h4 modal-title">
    <span i18n>Edit sketch</span>
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="dismiss()">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <cc-sketch-pad [model]="model"></cc-sketch-pad>
</div>
<div class="modal-footer justify-content-center">
  <button type="button" class="btn-save btn btn-success" i18n (click)="modal.close()">Save sketch</button>
</div>
