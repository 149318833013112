import { FormItemChangeDetectorFactory } from './form-item-change-detector-factory';
import { FieldWeb } from '../../shared/model/item.model';

export class FormItemsChangeDetector {
  static hasAnyItemChanged(oldItems: FieldWeb[], newItems: FieldWeb[]) {
    const oldItemsMap = new Map<string, FieldWeb>();
    oldItems.forEach((item) => {
      oldItemsMap.set(item._id || item.id, item);
    });

    return newItems.some((newItem) => {
      const oldItem = oldItemsMap.get(newItem._id || newItem.id);

      if (newItem.readOnly || !oldItem) {
        return false;
      }

      if (newItem.kind !== oldItem.kind) {
        return true;
      }

      const changeDetector = FormItemChangeDetectorFactory.createChangeDetector(newItem.kind);
      return changeDetector.hasChanged(newItem, oldItem);
    });
  }
}
