import { Component } from '@angular/core';

import { DashpivotEvent, EventNotifierService, EventTypes } from '@site-mate/dashpivot-shared-library';

import { SegmentService } from 'app/segment/segment.service';
import { BannerKeys } from 'app/shared/model/banner-keys.enum';
import { BannerMetadata } from 'app/shared/model/banner-metadata.model';

@Component({
  selector: 'cc-form-available-on-mobile-alert',
  templateUrl: './form-available-on-mobile-alert.component.html',
  styleUrls: ['./form-available-on-mobile-alert.component.scss'],
})
export class FormAvailableOnMobileAlertComponent {
  readonly bannerMetadata = BannerMetadata;
  readonly bannerKeys = BannerKeys;
  readonly appStoreLink = 'https://apps.apple.com/au/app/dashpivot/id1093802452';
  readonly googlePlayLink = 'https://play.google.com/store/apps/details?id=io.constructioncloud';

  constructor(private readonly segmentService: SegmentService) {}

  trackPlatformLinkClick(): void {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.CtaClicked, { Context: 'Form Modal Mobile Prompt' }),
      this.segmentService,
    );
  }

  onClose(): void {
    void EventNotifierService.notify(
      new DashpivotEvent(EventTypes.CtaClosed, { Context: 'Form Modal Mobile Prompt' }),
      this.segmentService,
    );
  }
}
