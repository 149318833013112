<div>
  <div class="form-group row" [class.invalid-field]="model._invalid">
    <div class="col-12 row">
      <cc-form-field-label [model]="model" class="col-3 form-label" [label]="model.description" />
      <div class="col-9 sm-pt-4">
        <div class="form-control-static sketch-container">
          <div class="hint-text-container" (click)="onEditSketch()" *ngIf="!model.readOnly">
            <h2 class="h2 hint-text">Click to edit</h2>
          </div>
          <cc-sketch-pad-image [model]="model"></cc-sketch-pad-image>
        </div>
        <div class="row">
          <div *ngIf="model._invalid" class="col sm-text-default-red" i18n>{{ invalidDescription() }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
