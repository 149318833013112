import { Component, Input } from '@angular/core';

@Component({
  selector: 'cc-form-pdf-preview',
  templateUrl: 'form-pdf-preview.component.html',
  styleUrls: ['form-pdf-preview.component.scss'],
})
export class FormPdfPreviewComponent {
  @Input() attachment;
}
