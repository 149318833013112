<div class="form-group row" [class.invalid-field]="model._invalid">
  <cc-form-field-label class="col-12 form-label" [model]="model" [label]="model.description" />
  <div class="col-12">
    <cc-banner
      *ngIf="showTipActionBanner"
      type="info"
      showBadge="true"
      badgeText="Tip"
      dismissible="true"
      class="tip-action-banner-container"
      width="fit-content"
      customClasses="sm-mb-1"
      ccBannerLogic
      [bannerKey]="bannerKeys.FormTableActions"
      [localStorageKey]="bannerMetadata[bannerKeys.FormTableActions].localStorageKey"
    >
      <div i18n>
        You can access actions within tables by clicking the '<em
          class="cc-icon cc-icon-ellipsis-horizontal"
          aria-label="Table actions"
        ></em
        >' button. These actions are also available in the mobile app.
      </div>
    </cc-banner>
    <div class="form-control-static table-container">
      <div class="position-relative form-table">
        <cc-form-table-core
          [model]="model"
          [template]="template"
          class="no-bottom-space"
          [formPath]="formPath"
          [isReadOnly]="model.readOnly"
          [formId]="formId"
          [isPreview]="isPreview"
          [parentId]="parentId"
          [isLandscapeMode]="isLandscapeMode"
          (onChangeField)="validateTable(); this.onFieldChange()"
          (invalid)="invalidTable.emit($event)"
          #coreTable
        >
        </cc-form-table-core>
      </div>
      <div class="sm-flex sm-items-center">
        <button
          i18n
          type="button"
          class="btn btn-primary cta-button new-button mr-2"
          (click)="addRow(); this.onFieldChange()"
          *ngIf="!model.readOnly && !isPrefilledTable"
          data-test="add-new-row-button"
          title="Add new row"
        >
          <i class="cc-icon cc-icon-plus cc-icon-large" aria-hidden="true"></i>
        </button>
        <cc-ask-sam-to-import
          *ngIf="isSamDocketImportEnabled"
          [formItem]="model"
          [taskId]="model.samTask._id"
          [hierarchy]="hierarchy"
          (onSamResponseReceived)="onSamResponseReceived($event)"
        ></cc-ask-sam-to-import>
        <div
          class="btn import-button"
          [ngbPopover]="descriptions.import"
          [placement]="['bottom', 'right', 'top']"
          container="body"
          triggers="mouseenter:mouseleave"
          *ngIf="!model.readOnly && !isPrefilledTable"
          i18n
        >
          Copy/Paste Import
          <i class="fa fa-question-circle-o fa-fw app-control-question-marker"></i>
        </div>
        <div
          class="btn import-button"
          [ngbPopover]="descriptions.keyboardShortcuts"
          [placement]="['bottom', 'right', 'top']"
          container="body"
          triggers="mouseenter:mouseleave"
          *ngIf="!model.readOnly && !isPrefilledTable"
          i18n
        >
          Keyboard Shortcuts
          <i class="fa fa-question-circle-o fa-fw app-control-question-marker" aria-hidden="true"></i>
        </div>
        <cc-sam-banner *ngIf="model.samMetadata?.executed"></cc-sam-banner>
      </div>
    </div>
    <div class="row">
      <div *ngIf="model._invalid" class="col sm-text-default-red" i18n>
        {{ invalidDescription() }}
      </div>
    </div>
  </div>
</div>
