import {
  Component,
  EventEmitter,
  OnInit,
  Output,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import {
  DashpivotEvent,
  EventNotifierService,
  EventTypes,
  FormFieldValidatorFactory,
  FormFieldValidator,
} from '@site-mate/dashpivot-shared-library';

import { FormService } from 'app/form/form.service';
import { EditFormFieldComponent } from 'app/form-fields/common/edit/edit-form-field.component';
import { SegmentService } from 'app/segment/segment.service';
import { FieldWeb } from 'app/shared/model/item.model';
import { ToastrService } from 'app/shared/service/toastr.service';

import { FormSketchModalComponent } from './form-sketch-modal.component';

@Component({
  selector: 'cc-sketch-edit',
  templateUrl: './sketch-edit.component.html',
  styleUrls: [
    './sketch-edit.component.scss',
    '../../../shared/component/signature-pad/signature-pad.component.scss',
    '../../../form/form-components/form-component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SketchEditComponent implements EditFormFieldComponent, OnInit {
  fieldData: any;
  model: FieldWeb;
  validator: FormFieldValidator;
  dirty = false;
  isPreview: boolean;

  @Output() editSketch = new EventEmitter();

  constructor(
    private readonly segmentService: SegmentService,
    private readonly modal: NgbModal,
    private readonly toastrService: ToastrService,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly formService: FormService,
  ) {}

  ngOnInit(): void {
    this.model = this.fieldData.model;
    this.validator = FormFieldValidatorFactory.getValidator(this.model);
  }

  invalidDescription() {
    return this.validator.invalidDescription(this.model);
  }

  onChange() {
    this.dirty = true;
    this.model._invalid = !this.validator.isValid(this.model);
    this.changeDetectorRef.markForCheck();
  }

  onEditSketch() {
    if (this.isPreview) {
      this.toastrService.warnByKey('formPreviewWarningSketch');
      return;
    }

    void EventNotifierService.notify(new DashpivotEvent(EventTypes.FormSketchAdded), this.segmentService);

    const item = this.model;
    const modalRef = this.modal.open(FormSketchModalComponent, {
      windowClass: 'sketch-pad-modal',
      keyboard: false,
      backdrop: 'static',
    });
    modalRef.componentInstance.model = item;

    void modalRef.result
      // eslint-disable-next-line promise/always-return
      .then(() => {
        item.canvas = JSON.stringify(item.canvasObj.toJSON());
        item.img = item.canvasObj.toDataURL('png');
        this.dirty = true;
        this.model._invalid = !this.validator.isValid(this.model);
        this.changeDetectorRef.markForCheck();
      })
      .catch(() => {
        // no-op
      })
      .finally(() => this.formService.onFieldChanged.next());
  }
}
