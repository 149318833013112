import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

import { FormFieldValidator } from '@site-mate/dashpivot-shared-library';

import { EditFormFieldComponent } from 'app/form-fields/common/edit/edit-form-field.component';
import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { FieldWeb } from 'app/shared/model/item.model';

@Component({
  selector: 'cc-prefilled-text-edit',
  templateUrl: './prefilled-text-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['../../../form/form-components/form-component.scss'],
})
export class PrefilledTextEditComponent implements EditFormFieldComponent, OnInit {
  constructor(private readonly i18nService: TmpI18NService) {}

  fieldData: any;
  model: FieldWeb;
  dirty = false;
  validator: FormFieldValidator;
  popoverMessage: string;

  ngOnInit() {
    this.model = this.fieldData.model;
    this.popoverMessage = this.i18nService.getMessage('prefilledCellPopover');
  }
}
