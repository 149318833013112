<div *ngIf="model.kind === controlType.date">
  <div class="form-group row" [class.invalid-field]="model._invalid">
    <div class="row col-12">
      <cc-form-field-label [model]="model" class="col-3 form-label" [label]="model.description" />
      <div *ngIf="!model.readOnly" class="col-9 form-inline">
        <div>
          <div class="form-inline flex-nowrap">
            <div class="form-group" [class.mr-2]="model.isDateAndTime">
              <cc-date-picker
                data-test="date-picker"
                ccScrollInputFocus=".form-modal"
                [(ngModel)]="model._startDate"
                (ngModelChange)="validate($event); calculateExpiry($event); onFieldChange()"
                name="startDate"
                icon="calendar-clock"
              >
              </cc-date-picker>
            </div>
            <div class="form-group" *ngIf="model.isDateAndTime">
              <cc-time-picker
                data-test="time-picker"
                [model]="model._startTime"
                (changeValue)="validateStartTime($event); onFieldChange()"
              >
              </cc-time-picker>
            </div>
          </div>
        </div>
        <div class="text-muted m-3 hidden-xs-down" *ngIf="!model.isSingleDate">&mdash;</div>
        <div *ngIf="!model.isSingleDate">
          <div class="form-inline">
            <div class="form-group" [class.mr-2]="model.isDateAndTime">
              <cc-date-picker
                data-test="single-date-picker"
                ccScrollInputFocus=".form-modal"
                [(ngModel)]="model._endDate"
                (ngModelChange)="validate($event); onFieldChange()"
                name="endDate"
                icon="calendar-clock"
              >
              </cc-date-picker>
            </div>
            <div class="form-group" *ngIf="model.isDateAndTime">
              <cc-time-picker
                data-test="single-time-picker"
                [model]="model._endTime"
                (changeValue)="validateEndTime($event); onFieldChange()"
              >
              </cc-time-picker>
            </div>
          </div>
        </div>
        <div class="ml-auto" *ngIf="model.isExpiryDate">
          <div *ngIf="expiry >= 0" class="expiry-date">
            <i class="cc-icon cc-icon-warning sm-text-default-orange"></i><span i18n>Expires in </span>
            <span>{{ expiry }}</span>
            <span i18n>days</span>
          </div>
          <div *ngIf="expiry < 0" class="expiry-date">
            <i class="cc-icon cc-icon-warning sm-text-default-red"></i><span i18n>Expired</span>
          </div>
        </div>
      </div>
      <div *ngIf="model.readOnly" class="col-9 form-inline">
        <div class="form-inline">
          <div class="form-inline">
            <div class="form-group">
              <span>{{ model.startDate | amDateFormat: 'll' }}</span>
              <span class="ml-1" *ngIf="model.isDateAndTime"
                >{{ model.startDate | amDateFormat: 'LT' }}
              </span>
            </div>
          </div>
          <div class="text-muted m-3" *ngIf="!model.isSingleDate">&mdash;</div>
          <div *ngIf="!model.isSingleDate">
            <div class="form-inline">
              <div class="form-group">
                <span>{{ model.endDate | amDateFormat: 'll' }}</span>
                <span class="ml-1" *ngIf="model.isDateAndTime"
                  >{{ model.endDate | amDateFormat: 'LT' }}
                </span>
              </div>
            </div>
          </div>
          <div class="ml-auto" *ngIf="model.isExpiryDate">
            <div *ngIf="expiry >= 0">
              <i class="fa fa-fw fa-warning text-warning"></i><span i18n>Expires in </span>
              <span>{{ expiry }}</span>
              <span i18n>days</span>
            </div>
            <div *ngIf="expiry < 0">
              <i class="fa fa-fw fa-warning sm-text-default-red"></i><span i18n>Expired</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row col-12">
      <div *ngIf="model._invalid" class="col-10 offset-2 sm-text-default-red" i18n>
        {{ invalidDescription() }}
      </div>
    </div>
  </div>
</div>
