import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { kebabCase } from 'lodash-es';

import { TmpI18NService } from 'app/i18n/tmp-i18n.service';
import { FormWeb } from 'app/shared/model/form.model';
import { IFrameUrlKey } from 'app/shared/model/iframe-url-key.enum';
import { AppUtilService } from 'app/shared/service/app-util.service';
import { ToastrService } from 'app/shared/service/toastr.service';
import { environment } from 'environments/environment';

import { FormSignUpToDownloadModalComponent } from './edit-form/form-sign-up-to-download-modal/form-sign-up-to-download-modal.component';

@Component({
  selector: 'cc-form-modal-tool-bar',
  templateUrl: 'form-modal-tool-bar.component.html',
  styleUrls: ['../../styles/components/modal-tool-bar.scss'],
})
export class FormModalToolBarComponent implements OnInit, OnChanges {
  @Input({
    transform: (value: string) => kebabCase(value),
  })
  templateName: string;
  @Input() canDelete: boolean;
  @Input() canToggleActivity: boolean;
  @Input() canSave: boolean;
  @Input() activitiesState: boolean;
  @Input() isWorking: boolean;
  @Input() form: FormWeb;
  @Input() isPreview: boolean;
  @Input() isCloning: boolean;
  @Input() showActions: boolean;
  @Input() formName: string;
  @Input() navigateOnSignUpToFormModal: boolean;
  @Input() templateId: string;

  @Output() remove = new EventEmitter();
  @Output() download = new EventEmitter();
  @Output() clone = new EventEmitter();
  @Output() close = new EventEmitter();
  @Output() save = new EventEmitter();
  @Output() send = new EventEmitter();
  @Output() customise = new EventEmitter();
  @Output() useThisTemplate = new EventEmitter<void>();
  @Output() toggleActivities = new EventEmitter<boolean>();

  isEmbedLibraryApp = false;
  baseUrl = environment.uiUrl;
  signupUrl: string;

  constructor(
    public readonly appUtilService: AppUtilService,
    public readonly activeModal: NgbActiveModal,
    private readonly toastr: ToastrService,
    private readonly location: Location,
    private readonly ngbModal: NgbModal,
    private readonly i18nService: TmpI18NService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.templateName || changes.templateId) {
      this.signupUrl = `${this.baseUrl}/signup?reference=${this.templateName}-template-iframe&templateId=${this.templateId}`;
    }
  }

  ngOnInit(): void {
    this.setupIFrameEmbed();
  }

  private setupIFrameEmbed() {
    const url = this.location.path();
    const isIFrameEmbed = url.includes(`/${IFrameUrlKey.LibraryApps}`);
    if (isIFrameEmbed) {
      this.isEmbedLibraryApp = true;
    }
  }

  onRemove() {
    if (this.canDelete) {
      this.remove.emit();
    } else {
      this.toastr.errorByKey('noPermissionDelete');
    }
  }

  onToggleActivity() {
    this.toggleActivities.emit();
  }

  openSignUpToDownloadModal() {
    const signupToDownloadModal = this.ngbModal.open(FormSignUpToDownloadModalComponent, {
      windowClass: 'modal-500 modal-overlay',
    });
    signupToDownloadModal.componentInstance.signupUrl = this.signupUrl;
    signupToDownloadModal.componentInstance.title = this.i18nService.getMessage('signUpToDownload');
    signupToDownloadModal.componentInstance.buttonLabel =
      this.i18nService.getMessage('signUpDownloadButtonLabel');
  }
}
