import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

import { FormSources } from '@site-mate/dashpivot-shared-library';

import { FormWeb } from 'app/shared/model/form.model';

@Component({
  selector: 'cc-author-data',
  templateUrl: './author-data.component.html',
  styleUrls: ['./author-data.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthorDataComponent {
  @Input() isEditForm: boolean;
  @Input() form: FormWeb;

  readonly formSources = FormSources;
}
